type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

const Form = ({ onSubmit, children, ...props }: Props) => {
  const newOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (onSubmit) onSubmit(event)
  }

  return (
    <form onSubmit={newOnSubmit} {...props}>
      {children}
    </form>
  )
}

export default Form
