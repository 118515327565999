import React from 'react'
import MagnifyingGlass from './svgs/MagnifyingGlass'

const Search = ({ query: initQuery }: { query?: string }) => {
  const [query, setQuery] = React.useState(initQuery)

  return (
    <form action="/search" method="get">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="flex rounded-md">
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for item, part number, keyword..."
          className="block w-full px-3 py-2 border-l border-t border-b border-gray-200 rounded-l-md bg-white placeholder-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue transition duration-150 ease-in-out"
          id="text"
          name="q"
          type="text"
        />
        <button
          type="submit"
          className="w-10 cursor-pointer bg-gray-100 border-r border-t border-b border-gray-200 rounded-r-md"
        >
          <MagnifyingGlass />
        </button>
      </div>
    </form>
  )
}

export default Search
