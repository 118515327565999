import Money from './Money'

import { SourceProduct, SourceProductPayload } from '../../types'

const fromPayload = (payload: SourceProductPayload): SourceProduct => ({
  ...payload,
  shippingCost: payload.shippingCost
    ? Money.fromPayload(payload.shippingCost)
    : payload.shippingCost,
})

export default { fromPayload }
