import HomeIcon from './Vendors/HomeIcon'
import vendors from './Vendors/vendors.png'
import vendorsMobile from './Vendors/vendors-mobile.png'
import { motion } from 'framer-motion'
import classNames from 'classnames'

const title = 'One place for all your vendors'

const Vendors = () => (
  <motion.section className="grid grid-cols-1 sm:grid-cols-[auto_22rem] xl:grid-cols-[auto_33rem] items-center gap-y-8 sm:gap-x-12 xl:gap-x-16">
    <figure className="sm:hidden order-2 relative w-full">
      <div className="relative w-[130vw] -left-[35vw]">
        <img src={vendorsMobile} className="w-full h-full object-cover" alt={title} />
      </div>
    </figure>
    <motion.figure className="relative h-[29rem] xl:h-[39rem] mt-4 sm:mt-0 hidden sm:block order-1">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-200px 1px' }}
        transition={{ duration: 0.5 }}
        variants={{
          visible: {
            opacity: 1,
            x: 0,
            transition: { type: 'spring', stiffness: 300, damping: 24 },
          },
          hidden: { opacity: 0, x: -200 },
        }}
        className="hidden sm:block absolute inset-y-0 w-[45rem] xl:w-[58rem] right-0"
      >
        <img src={vendors} className="" alt={title} />
      </motion.div>
    </motion.figure>
    <div
      className={classNames(
        'space-y-4 sm:space-y-8 w-full sm:w-[20rem] xl:w-[34.75rem] text-center sm:text-left order-1 sm:order-2'
      )}
    >
      <div className="flex justify-center sm:justify-start">
        <HomeIcon />
      </div>
      <h3 className="font-medium text-xl sm:text-3xl leading-6 sm:leading-9">{title}</h3>
      <p className="text-base sm:text-lg leading-6 sm:leading-7">
        A dozen portals and processes for your mixed fleet? Who&apos;s got time for that! Work with
        all your vendors and alternative options in a single spot.
      </p>
    </div>
  </motion.section>
)

export default Vendors
