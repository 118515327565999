import { useQuery, gql, QueryHookOptions } from '@apollo/client'

import { SourceProductsSearch } from '../types'

interface Data {
  sourceProductsSearch: SourceProductsSearch
}

interface Variables {
  page?: number
  search?: string
  slugPath?: string
  inSubtreeOfCategorySlugPath?: string
}

const query = gql`
  query SearchSourceProducts(
    $page: Int
    $search: String
    $slugPath: String
    $inSubtreeOfCategorySlugPath: String
  ) {
    sourceProductsSearch(
      page: $page
      search: $search
      slugPath: $slugPath
      inSubtreeOfCategorySlugPath: $inSubtreeOfCategorySlugPath
    ) {
      sourceProducts {
        id
        name
        slugPath
        mpn
        altMpn
        sku
        shortDescription
        longDescription
        listPrice {
          amount
          currency
        }
        shippingCost {
          amount
          currency
        }
        availability
        leadTime
        isOem
        images {
          variants {
            name
            url
          }
        }
        category {
          id
        }
        products {
          id
          slugPath
          availability
          salePrice {
            amount
            currency
          }
          store {
            name
            city
            state
            slug
          }
        }
      }
      pagination {
        totalPages
        totalResults
      }
    }
  }
`

const useSearchSourceProducts = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(query, options)

export default useSearchSourceProducts
