import { Link } from 'react-router-dom'

import Money from '@/gf/modules/Money'
import ProductM from '@/gf/modules/Product'

import OemBadge from './OemBadge'

import { Product } from '../types'

const ProductCard = ({ product }: { product: Product }) => (
  <Link to={`/itm/${product.slugPath}`} className="block">
    <img
      src={ProductM.primaryImageURL(product, 'card')}
      alt={product.name}
      className="block mb-1"
    />
    <div className="mb-2 flex gap-2">
      <div className="font-bold">{product.name}</div>
      {product.isOem && (
        <div>
          <OemBadge />
        </div>
      )}
    </div>
    <div className="mb-2">Part Number: {product.mpn}</div>
    <div>{Money.format(product.salePrice)}</div>
  </Link>
)

export default ProductCard
