import Rollbar from 'rollbar'

declare const gfConfig: string | undefined

const serverConfig = (() => {
  if (typeof gfConfig === 'undefined') return undefined

  try {
    return JSON.parse(window.atob(gfConfig)) as {
      rollbar: {
        enabled: boolean
        captureUncaught: boolean
        captureUnhandledRejections: boolean
      }
    }
  } catch {
    return undefined
  }
})()

const rollbar: Rollbar.Configuration = {
  accessToken: process.env.ROLLBAR_JS_ACCESS_TOKEN,
  enabled: serverConfig ? serverConfig.rollbar.enabled : true,
  captureUncaught: serverConfig ? serverConfig.rollbar.captureUncaught : true,
  captureUnhandledRejections: serverConfig ? serverConfig.rollbar.captureUnhandledRejections : true,
  hostSafeList: ['gearflow.com'],
  payload: {
    environment: process.env.ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
}

export default {
  rollbar,
  smartyEmbeddedKey: process.env.SMARTY_EMBEDDED_KEY,
  devSsl: process.env.DEV_SSL,
  canonicalHost: process.env.CANONICAL_HOST,
  balanceEnv: process.env.BALANCE_ENV,
  devsBaseUrl: process.env.DEVS_BASE_URL || 'https://devs.local.gearflow.com:5173',
  stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
}
