import { useContext } from 'react'
import Context from '../contexts/GqlClient'

const useGqlClient = () => {
  const context = useContext(Context)
  if (!context) throw new Error('must be used within provider')
  return context
}

export default useGqlClient
