import { motion } from 'framer-motion'
import PhoneIcon from './Requests/PhoneIcon'
import requests from './Requests/requests.png'
import requestsMobile from './Requests/requests_mobile.png'

const title = 'Instant messaging = no more miscommunication'

const Requests = () => (
  <motion.section className="grid grid-cols-1 sm:grid-cols-[22rem_auto] xl:grid-cols-[33rem_auto] items-center gap-y-8 sm:gap-x-12 xl:gap-x-16">
    <div className="space-y-4 sm:space-y-8 w-full sm:w-[22rem] xl:w-[34.75rem] text-center sm:text-left">
      <div className="flex justify-center sm:justify-start">
        <PhoneIcon />
      </div>
      <h3 className="font-medium text-xl sm:text-3xl leading-6 sm:leading-9">{title}</h3>
      <p className="text-base sm:text-lg leading-6 sm:leading-7">
        Your days of phone tag are over. Cut phone calls in half and clear up confusion by easily
        messaging your team and vendors on requests and orders.
      </p>
    </div>

    <figure className="sm:hidden relative w-full">
      <div className="relative w-[111vw] right-[12vw]">
        <img src={requestsMobile} className="w-full h-full object-cover" alt={title} />
      </div>
    </figure>

    <motion.figure className="relative hidden sm:block h-[31rem] xl:h-[43rem] mt-4 sm:mt-0">
      <motion.div className="hidden sm:block absolute inset-y-0 w-[45.25rem] -left-[1rem] xl:w-[62.25rem] xl:-left-[2rem]">
        <motion.img
          src={requests}
          className="object-contain"
          alt={title}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-200px 1px' }}
          transition={{ duration: 0.5 }}
          variants={{
            visible: {
              opacity: 1,
              x: 0,
              transition: { type: 'spring', stiffness: 300, damping: 24 },
            },
            hidden: { opacity: 0, x: 200 },
          }}
        />
      </motion.div>
    </motion.figure>
  </motion.section>
)

export default Requests
