const Tag = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 6H14C11.8783 6 9.84344 6.84285 8.34315 8.34315C6.84286 9.84344 6 11.8783 6 14V24C5.99946 24.5253 6.10272 25.0456 6.30385 25.5309C6.50498 26.0163 6.80001 26.457 7.172 26.828L21.172 40.828C21.9221 41.5779 22.9393 41.9991 24 41.9991C25.0607 41.9991 26.0779 41.5779 26.828 40.828L40.828 26.828C41.5779 26.0779 41.9991 25.0607 41.9991 24C41.9991 22.9393 41.5779 21.9221 40.828 21.172L26.828 7.172C26.048 6.39 25.024 6 24 6Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="15" cy="15" r="3" fill="white" />
  </svg>
)

export default Tag
