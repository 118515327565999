/**
 * useWindowWidth Hook
 * -----------------
 * Use this to responsively change props that influence visual behavior based on window size.
 * -----------------
 * Tailwind responsive window size break-points:
 * sm:  640px
 * md:  768px
 * lg:  1024px
 * xl:  1280px
 * 2xl: 1536px
 */

import { debounce } from 'lodash'
import React, { useEffect, useRef } from 'react'

const useWindowWidth = (debounceWait = 5) => {
  const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth)

  const resizeListener = useRef(
    debounce(() => {
      setWindowWidth(window.innerWidth)
    }, debounceWait)
  ).current

  useEffect(() => {
    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  return windowWidth
}
export default useWindowWidth
