const CloseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29303 4.29259C4.48056 4.10512 4.73487 3.99981 5.00003 3.99981C5.26519 3.99981 5.5195 4.10512 5.70703 4.29259L10 8.58559L14.293 4.29259C14.3853 4.19708 14.4956 4.1209 14.6176 4.06849C14.7396 4.01608 14.8709 3.9885 15.0036 3.98734C15.1364 3.98619 15.2681 4.01149 15.391 4.06177C15.5139 4.11205 15.6255 4.18631 15.7194 4.2802C15.8133 4.37409 15.8876 4.48574 15.9379 4.60864C15.9881 4.73154 16.0134 4.86321 16.0123 4.99599C16.0111 5.12877 15.9835 5.25999 15.9311 5.382C15.8787 5.504 15.8025 5.61435 15.707 5.70659L11.414 9.99959L15.707 14.2926C15.8892 14.4812 15.99 14.7338 15.9877 14.996C15.9854 15.2582 15.8803 15.509 15.6948 15.6944C15.5094 15.8798 15.2586 15.985 14.9964 15.9873C14.7342 15.9895 14.4816 15.8888 14.293 15.7066L10 11.4136L5.70703 15.7066C5.51843 15.8888 5.26583 15.9895 5.00363 15.9873C4.74143 15.985 4.49062 15.8798 4.30521 15.6944C4.1198 15.509 4.01463 15.2582 4.01236 14.996C4.01008 14.7338 4.11087 14.4812 4.29303 14.2926L8.58603 9.99959L4.29303 5.70659C4.10556 5.51907 4.00024 5.26476 4.00024 4.99959C4.00024 4.73443 4.10556 4.48012 4.29303 4.29259Z"
      fill="#111827"
    />
  </svg>
)

export default CloseIcon
