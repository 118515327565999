import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet'

import useSearchSourceProducts from '../hooks/useSearchSourceProducts'
import usePage from '@/gf/hooks/usePage'

import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import SourceProducts from '../components/SourceProducts'
import Pagination from '../components/Pagination'

const useQueryParamsOpts = { q: withDefault(StringParam, '') }

const Search = () => {
  const [page, setPage] = usePage()
  const query = useQueryParams(useQueryParamsOpts)[0]

  const search = useSearchSourceProducts({ variables: { page, search: query.q } }).data
    ?.sourceProductsSearch

  return (
    <Layout searchQuery={query.q}>
      <Helmet>
        <title>Results for &ldquo;{query.q}&rdquo; | Gearflow</title>
        <meta content="equipment, marketplace, parts, construction" name="keywords" />
        <meta
          content={`Searching for ${query.q}? Search no further! Gearflow is the home to the largest inventory of parts and gear.`}
          name="description"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container className="py-4">
        <div className="space-y-4">
          <h1 className="text-2xl leading-9 font-bold text-gray-700 tracking-tight sm:text-3xl px-4 sm:px-0">
            Results for &ldquo;{query.q}&rdquo;
          </h1>

          {search && search.pagination.totalResults > 1 && (
            <p className="text-sm leading-5 text-gray-700">
              <>{search.pagination.totalResults.toLocaleString()} results</>
            </p>
          )}

          <SourceProducts sourceProducts={search?.sourceProducts} />

          <Pagination pagination={search?.pagination} page={page} updatePage={setPage} />
        </div>
      </Container>
    </Layout>
  )
}

export default Search
