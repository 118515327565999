import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import Checkbox from '@/gf/components/inputs/Checkbox'
import TextInput from '@/gf/components/inputs/Text'
import {
  App,
  SignUpError,
  usePasswordSignUpMutation,
  useSsoSignInMutation,
  useSsoSignUpMutation,
} from '@/retail/_gen/gql'
import useGqlClient from '@/retail/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { popReturnPath } from '../SignIn/Form'
import useSignIn from '../SignIn/useSignIn'
import FocusLayout from './FocusLayout'
import useSignUp from './useSignUp'

const DetailsStep = ({ app }: { app: App }) => {
  const { fields, errors, updateFields, setErrors, reset } = useSignUp()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const client = useGqlClient()
  const [ssoSignUp] = useSsoSignUpMutation({ client })
  const [ssoSignIn] = useSsoSignInMutation({ client })
  const [passwordSignUp] = usePasswordSignUpMutation({ client })
  const { signIn } = useSignIn()
  const [goTo, setGoTo] = useState<string>()

  useEffect(() => {
    if (goTo) navigate(goTo)
  }, [goTo])

  const signUpErrored = (signUpError: SignUpError | undefined) => {
    setErrors(signUpError || null)
    setSubmitting(false)
    if (signUpError?.email) setGoTo('..')
    else if (signUpError?.password || signUpError?.passwordConfirmation) setGoTo('../password')
  }

  const submit = async () => {
    setSubmitting(true)
    setErrors(null)
    const email = fields.sso ? fields.sso.email : fields.email

    const signUpVars = {
      app,
      email,
      membershipId: fields.companyName ? 'BUSINESS' : 'PERSONAL',
      companyName: fields.companyName,
      name: fields.name,
      phoneNumber: fields.phoneNumber,
      emailOptIn: fields.emailOptIn,
      code: fields.code,
    }

    if (fields.sso) {
      const { data: signUpData } = await ssoSignUp({
        variables: {
          ...signUpVars,
          linkId: fields.sso.linkId,
          linkType: fields.sso.linkType,
        },
      })

      if (signUpData?.ssoSignUp.__typename === 'SignUpOk') {
        const { data: signInData } = await ssoSignIn({
          variables: { linkId: fields.sso.linkId, linkType: fields.sso.linkType },
        })

        reset()

        if (signInData?.ssoSignIn) window.location.href = popReturnPath() || signInData.ssoSignIn
      } else {
        signUpErrored(signUpData?.ssoSignUp)
      }
    } else {
      const { data: signUpData } = await passwordSignUp({
        variables: {
          ...signUpVars,
          password: fields.password,
          passwordConfirmation: fields.passwordConfirmation,
        },
      })

      if (signUpData?.signUp.__typename === 'SignUpOk') {
        await signIn({ email: fields.email, password: fields.password })
        reset()
      } else {
        signUpErrored(signUpData?.signUp)
      }
    }
  }

  return (
    <FocusLayout app={app}>
      <Form onSubmit={submit} className="space-y-6 border-1 p-8 rounded-lg bg-white">
        <div className="text-gray-900 text-base text-center leading-tight">
          We just need a few more details to create your account.
        </div>

        <div className="space-y-4">
          <Field label="First &amp; last name" errors={errors?.name} htmlFor="name">
            <TextInput
              autoFocus
              placeholder="Your name"
              value={fields.name}
              setValue={(name) => updateFields({ name })}
              id="name"
            />
          </Field>

          <Field label="Phone number" errors={errors?.phoneNumber}>
            <TextInput
              placeholder="Your phone number"
              value={fields.phoneNumber}
              setValue={(phoneNumber) => updateFields({ phoneNumber })}
            />
          </Field>

          <Field label="Company name" errors={errors?.companyName}>
            <TextInput
              placeholder="Your company's name"
              value={fields.companyName}
              setValue={(companyName) => updateFields({ companyName })}
            />
          </Field>

          <div className="flex flex-col md:w-full">
            <div className="flex gap-2 items-center md:w-full">
              <Checkbox
                checked={fields.emailOptIn}
                onChange={(e) => updateFields({ emailOptIn: e.target.checked })}
                type="checkbox"
                aria-describedby="form_email_opt_in"
                className="rounded border-gray-300 text-gearflow"
                id="form_email_opt_in"
              />
              <label
                htmlFor="form_email_opt_in"
                className="text-sm font-medium leading-5 text-gray-700"
              >
                Keep me up to date on Gearflow news and updates
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-3">
          <Action.P className="w-full font-medium" performing={submitting} type="submit">
            Create my account
          </Action.P>

          <label
            htmlFor="form_sms_agreement"
            className="text-xs font-medium leading-tight text-gray-500"
          >
            By signing up, you authorize Gearflow to send communications (including text messages if
            applicable) with offers & other information, possibly using automated technology.
            Consent is not a condition of purchase. Message/data rates apply.
          </label>
        </div>
      </Form>
    </FocusLayout>
  )
}

export default DetailsStep
