import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Container from '../components/Layout/Container'

const NotFound = () => (
  <Layout>
    <Helmet>
      <title>Page Not Found | Gearflow</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container className="py-4">
      <h2 className="text-xl">Page Not Found</h2>
    </Container>
  </Layout>
)

export default NotFound
