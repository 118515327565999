import React, { useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import useConfig from '@/gf/hooks/useConfig'
import Money from '@/gf/modules/Money'

const GqlClientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const config = useConfig()

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: `${config.gfBaseUrl}/gql`,
        cache: new InMemoryCache({
          typePolicies: {
            Product: {
              fields: {
                salePrice: {
                  read: Money.fromPayload,
                },
                shippingCost: {
                  read: Money.fromPayload,
                },
              },
            },
            SourceProduct: {
              fields: {
                listPrice: {
                  read: Money.fromPayload,
                },
                shippingCost: {
                  read: Money.fromPayload,
                },
              },
            },
          },
        }),
        credentials: 'include',
        defaultOptions: { query: { fetchPolicy: 'no-cache' } },
      }),
    [config.gfBaseUrl]
  )

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GqlClientProvider
