import Money from '@/gf/modules/Money'

import { Product } from '../types'

const SalePriceRange = ({ products }: { products?: Product[] }) => {
  if (products === undefined) return null

  const sortedSalePrices = products
    .filter((p) => !!p.salePrice)
    .map((p) => p.salePrice)
    .sort(Money.compare)

  const lowest = sortedSalePrices[0]
  const highest = sortedSalePrices[sortedSalePrices.length - 1]

  return (
    <>
      {Money.format(lowest)}
      {!Money.equals(lowest, highest) && <> - {Money.format(highest)}</>}
    </>
  )
}

export default SalePriceRange
