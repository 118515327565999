import Modal from './Modal'

interface Props {
  open: boolean
  onClose: () => void
  returnsAndWarrantyInfo: string
}
const ReturnsAndWarrantyInfoModal = ({ open, onClose, returnsAndWarrantyInfo }: Props) => (
  <Modal title="Returns and Warranty" open={open} onClose={onClose} cancelText="Close">
    <div
      className="whitespace-pre-wrap"
      dangerouslySetInnerHTML={{ __html: returnsAndWarrantyInfo }}
    />
  </Modal>
)

export default ReturnsAndWarrantyInfoModal
