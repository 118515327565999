import { Link } from 'react-router-dom'

import { Category } from '../types'

const CategoryTree = ({
  currentCategory,
  categories,
}: {
  currentCategory: Category
  categories?: Category[]
}) => (
  <>
    {categories &&
      categories.map((category) => (
        <div key={category.id}>
          <Link
            to={`/c/${category.slugPath}`}
            key={category.id}
            className={`block hover:bg-gray-300 px-3 py-1 ${
              currentCategory.id === category.id && 'bg-gray-300'
            }`}
          >
            {category.name}
          </Link>
          {category.children.length > 0 && (
            <div
              className={`ml-2 ${
                !(
                  currentCategory.id === category.id ||
                  currentCategory.ascendants.some((a) => a.id === category.id)
                ) && 'hidden'
              }`}
            >
              <CategoryTree currentCategory={currentCategory} categories={category.children} />
            </div>
          )}
        </div>
      ))}
  </>
)

export default CategoryTree
