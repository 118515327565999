import {
  FloatingPortal,
  Placement,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

const Dropdown = ({
  trigger,
  stopEventPropagation,
  children,
  disabled = false,
  placement = 'bottom-start',
  closeOnMenuClick = false,
}: {
  trigger: React.ReactNode
  stopEventPropagation?: boolean
  children: React.ReactNode
  disabled?: boolean
  placement?: Placement
  closeOnMenuClick?: boolean
}) => {
  const [open, setOpen] = useState(false)
  const { x, y, strategy, refs, context } = useFloating({
    placement,
    middleware: [flip()],
    open,
    onOpenChange: setOpen,
  })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        className="group disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
        disabled={disabled}
        onClick={(e) => {
          setOpen(!open)
          if (stopEventPropagation) e.stopPropagation()
        }}
        {...getReferenceProps()}
      >
        {trigger}
      </button>

      <FloatingPortal>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-in duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            ref={refs.setFloating}
            className="z-50"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps({
              onClick: () => {
                if (closeOnMenuClick) {
                  setOpen(!open)
                }
              },
            })}
          >
            {children}
          </div>
        </Transition>
      </FloatingPortal>
    </>
  )
}

export default Dropdown
