import { AnchorHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

interface LinkButton extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variation?: 'primary' | 'secondary'
}

const LinkButton = ({ className, children, variation = 'primary', ...props }: LinkButton) => (
  <a
    {...props}
    className={twMerge(
      'px-4 py-2 lg:py-3 text-sm lg:text-base text-center inline-block font-medium border shadow-base rounded-md lg:rounded-lg',
      variation === 'secondary'
        ? 'bg-white text-gray-700 border-gray-300'
        : 'bg-orange-500 text-white border-orange-500',
      className
    )}
  >
    {children}
  </a>
)

export default LinkButton
