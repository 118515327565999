const TrendingIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.6667 18.6665H56M56 18.6665V39.9998M56 18.6665L34.6667 39.9998L24 29.3332L8 45.3332"
      stroke="#111827"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TrendingIcon
