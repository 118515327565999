import { Link } from 'react-router-dom'

import useToggle from '@/gf/hooks/useToggle'
import useCategories from '../../../hooks/useCategories'

import ArrowIcon from './ArrowIcon'

import { type Category } from '../../../types'

interface TreeProps {
  categories: Category[]
}

const Category = ({
  category,
  TreeComponent,
}: {
  category: Category
  TreeComponent: (props: TreeProps) => JSX.Element
}) => {
  const [open, { toggle }] = useToggle(false)

  if (category.children.length === 0) {
    return (
      <Link to={`/c/${category.slugPath}`} className="py-2 block">
        {category.name}
      </Link>
    )
  }

  return (
    <>
      <button
        onClick={(_e) => toggle()}
        className="py-2 flex items-center w-full text-left cursor-pointer"
        type="button"
      >
        <div className="grow">{category.name}</div>
        <ArrowIcon open={open} />
      </button>

      <div className={`pl-4 ${open ? '' : 'hidden'}`}>
        <Link to={`/c/${category.slugPath}`} className="py-2 block">
          All {category.name}
        </Link>
        <TreeComponent categories={category.children} />
      </div>
    </>
  )
}

const CategoryTree = ({ categories }: TreeProps) => (
  <>
    {categories.map((category) => (
      <Category category={category} key={category.id} TreeComponent={CategoryTree} />
    ))}
  </>
)

const ShopNowNav = () => {
  const [open, { toggle }] = useToggle(false)
  const categories = useCategories()

  return (
    <>
      <button
        onClick={(_e) => toggle()}
        className="flex items-center w-full cursor-pointer text-left px-6 py-2"
        type="button"
      >
        <div className="grow">Shop Now</div>
        <ArrowIcon open={open} />
      </button>

      {open && categories && (
        <div className="bg-gray-100 py-2 px-6 text-base">
          <CategoryTree categories={categories} />
        </div>
      )}
    </>
  )
}

export default ShopNowNav
