import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props extends HTMLAttributes<HTMLDivElement> {
  childrenClassName?: string
}

const LayoutContainer = ({ children, className, childrenClassName, ...props }: Props) => (
  <div {...props} className={twMerge('w-full overflow-hidden', className)}>
    <div className={twMerge('max-w-[90rem] mx-auto px-8 sm:px-12 xl:px-16', childrenClassName)}>
      {children}
    </div>
  </div>
)

export default LayoutContainer
