import { Disclosure } from '@headlessui/react'
import {
  ChartBarIcon,
  ChatIcon,
  ChevronDownIcon,
  ClipboardCheckIcon,
  LightningBoltIcon,
  MailOpenIcon,
  TruckIcon,
} from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Helmet } from 'react-helmet'

import A from '@/gf/components/A'
import Layout from '../components/Layout'
import header from './BuyerSolutions/header.png'
import buyerDashScreenshot from './BuyerSolutions/partshub-dashboard-screenshot.png'
import quote from './BuyerSolutions/quote.png'
import support from './support.jpg'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const features = [
  {
    name: 'Centralize dealer access',
    description:
      'We know the relationships you have with your local dealers are key to your success. Send them your parts requests and pay them directly via pay direct.',
    icon: MailOpenIcon,
  },
  {
    name: 'Upload your fleet',
    description:
      'Add your machines for fitment accuracy, faster parts sourcing, and the ability to track parts costs down to the unit.',
    icon: TruckIcon,
  },
  {
    name: 'Source parts instantly',
    description:
      "Simply send the parts you need with the unit it's for, and we'll broadcast it to the largest network of suppliers that can help you fast.",
    icon: LightningBoltIcon,
  },
  {
    name: 'Message live',
    description:
      'Communicate with your dealers and collaborate with your internal team in real time. Track the history of comments on every request and order.',
    icon: ChatIcon,
  },
  {
    name: 'Manage your orders',
    description:
      'Monitor the status of every transaction tied to each machine across your mixed fleet. With advanced filtering, nothing gets lost in the shuffle.',
    icon: ClipboardCheckIcon,
  },
  {
    name: 'Automate your reports',
    description:
      "No need to pour hours into manually creating your repair and maintenance reports into spreadsheets. We'll do the work for you.",
    icon: ChartBarIcon,
  },
]

const BuyerSolutions = () => (
  <>
    <Helmet>
      <meta name="keywords" content="equipment, parts, construction, fleets, uptime" />
      <meta
        name="description"
        content="Gearflow simplifies parts ordering for heavy equipment fleets. Get better parts access, communication, and reporting all in one platform. Sign up free!"
      />
    </Helmet>
    <Layout>
      {/* Hero section */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img className="h-full w-full object-cover" src={header} alt="" />
              <div className="absolute inset-0 bg-gradient-to-r from-gearflow-dark to-gearflow-light mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">
                  Take parts pains off your plate &nbsp; &hellip;{' '}
                </span>
                <span className="block text-white">for good</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                Manage your entire parts procurement process, from ordering to communication to
                reporting, all in one easy-to-use platform.
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="flex justify-center">
                  <a
                    href="/signup"
                    className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gearflow bg-white  sm:px-8"
                  >
                    Sign up free
                  </a>
                  <p> &nbsp; &nbsp; &nbsp; </p>
                  <div className="flex justify-center">
                    <a
                      href="../pricing/parts-hub"
                      className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gearflow bg-white  sm:px-8"
                    >
                      View pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
              Source faster
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Your uptime partner
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              With Gearflow, someone always has your back — get easier access to your current
              dealers and discover a world of new ones.
            </p>
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src={buyerDashScreenshot}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
            Work smarter
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Single source of truth
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            End the madness of calls, texts, online portals, and spreadsheets to manage your parts
            ordering process. Make your job easier with parts access, communication, and reporting
            in one spot.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-gearflow-dark to-gearflow-light rounded-md shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pb-16 bg-gradient-to-r from-gearflow-dark to-gearflow-light lg:pb-0 lg:z-10 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative lg:-my-8">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <img className="object-cover lg:h-full lg:w-full" src={quote} alt="" />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-2xl font-medium text-white">
                    We&apos;re working with Gearflow to help us consolidate our parts sourcing
                    options, maintenance workflows, and cost data to ultimately reduce our
                    mechanics’ administrative time and the downtime on our equipment, as well as
                    simplify our invoicing. At Branch Civil, our equipment operations are a
                    differentiator in job profitability, and we’re a strong believer in implementing
                    digital tools that help us increase those margins.
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">Tim Morgan</p>
                  <p className="text-base font-medium text-white">
                    Equipment Operations Manager at Branch Civil
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <section id="faqs" aria-labelledby="faq-heading" className="bg-white">
        <div className="max-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
              <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Frequently asked questions
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                <Disclosure as="div" key="How do I access my Parts Hub dashboard?" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            How do I access my Parts Hub dashboard?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          First, you need to create your{' '}
                          <A.T href="/signup">free Gearflow account</A.T> — it only takes seconds
                          from start to finish!
                        </p>

                        <p className="mt-2 text-base text-gray-500">
                          If you already have a Gearflow account,{' '}
                          <A.T href="/login">log in to your Parts Hub dashboard here</A.T>.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" key="What’s the best way to get started?" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            What’s the best way to get started?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          Once you’re logged into your dashboard, we recommend getting started by
                          creating a Request for Quote (RFQ) so you can see what kinds of quotes you
                          can get back for a part you need.
                        </p>

                        <p className="mt-2 text-base text-gray-500">
                          Click the “Create Request” button in your dashboard landing page, add your
                          machine info (unit number, make, model, and serial number), the part
                          number or description, and other relevant details. Click “Submit RFQ” and
                          boom! You’re done. You’ll get an email when a quote is ready for your
                          review.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" key="What forms of payment do you accept?" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            What forms of payment do you accept?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          We accept all major credit cards for customers in the U.S. and Canada. For
                          international customers, we require a wire transfer before products are
                          shipped.
                        </p>

                        <p className="mt-2 text-base text-gray-500">
                          If you have invited one of your existing vendors to participate in an RFQ,
                          you can choose to pay direct and get invoiced as usual. We also offer
                          payment terms for qualified customers. Contact{' '}
                          <A.T href="mailto:support@gearflow.com">support@gearflow</A.T> for further
                          information.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" key="Where can I check my order status?" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            Where can I check my order status?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          With the Gearflow Parts Hub, you’ll never need to call, text, or email to
                          track down your order status again. You can view all order statuses, from
                          open quote to shipped, in the Orders tab of your Parts Hub dashboard.
                        </p>

                        <p className="mt-2 text-base text-gray-500">
                          To view tracking information on a shipped order, select the order and
                          click the “Track Shipment” button or copy and paste the tracking number
                          provided into the carrier’s website.
                        </p>
                        <p className="mt-2 text-base text-gray-500">
                          If your order has not shipped yet or if you have further questions about
                          the status of a shipped order, click on the Messages tab to comment live
                          with your supplier.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure
                  as="div"
                  key="How do I shop on the Gearflow Marketplace?"
                  className="pt-6"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            How do I shop on the Gearflow Marketplace?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          In addition to using the tools in the Gearflow Parts Hub, you can search
                          for your part and check out via self-serve on the Gearflow Marketplace.
                          Click the “Shop Now” dropdown menu in the navigation bar or use the search
                          bar at the top of the page.
                        </p>

                        <p className="mt-2 text-base text-gray-500">
                          If you cannot quickly find what you are looking for, log in to your Parts
                          Hub dashboard and create a parts request.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                <Disclosure as="div" key="What are your customer support hours?" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            What are your customer support hours?
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">
                          Our customer support team is available to help you from 8 a.m. to 7 p.m.
                          CT Monday through Friday, except holidays. Contact us about an individual
                          RFQ or order via Gearflow Messages or at{' '}
                          <A.T href="mailto:support@gearflow.com">support@gearflow.com</A.T>.
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </dl>
            </div>
          </div>
        </div>
      </section>

      <div className="relative bg-gray-900">
        <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img className="w-full h-full object-cover" src={support} alt="" />
          <div aria-hidden="true" className="absolute inset-0" />
        </div>
        <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              Unparalleled Support.
            </h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              We&apos;re here to help
            </p>
            <p className="mt-3 text-lg text-gray-300">
              We understand your pains because we have lived them. Our tools are designed to enhance
              the relationships that make this industry go, not replace them.
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="https://landing-page.gearflow.com/request-demo-equipment-owner"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                  Request a demo
                  <ExternalLinkIcon
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </>
)

export default BuyerSolutions
