import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import TextInput from '@/gf/components/inputs/Text'
import { App } from '@/retail/_gen/gql'
import omit from 'lodash/omit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FocusLayout from './FocusLayout'
import useSignUp from './useSignUp'

const PasswordStep = ({ app }: { app: App }) => {
  const navigate = useNavigate()
  const { fields, errors, updateFields, setErrors } = useSignUp()
  const [goNext, setGoNext] = useState(false)

  const submit = () => {
    const rest = omit(errors, ['password', 'passwordConfirmation'])

    const newErrors =
      fields.password.length < 8
        ? { password: ['Must be at least 8 characters'] }
        : fields.password !== fields.passwordConfirmation
          ? { passwordConfirmation: ["The passwords don't match"] }
          : null

    setErrors({ ...rest, ...newErrors })
    if (!newErrors) setGoNext(true)
  }

  useEffect(() => {
    if (goNext) navigate('../details')
  }, [goNext])

  return (
    <FocusLayout app={app}>
      <Form onSubmit={submit} className="space-y-6 border-1 p-8 rounded-lg bg-white">
        <div className="flex flex-col gap-y-2">
          <div className="text-gray-900 text-base text-center leading-tight font-medium">
            Let&apos;s set a password
          </div>
          <div className="text-gray-600 text-base leading-tight">
            For your security, we require that passwords be at least 8 characters.
          </div>
        </div>

        <div className="space-y-4">
          <Field label="Password" errors={errors?.password}>
            <TextInput
              autoFocus
              placeholder="Type your password"
              value={fields.password}
              setValue={(password) => updateFields({ password })}
              type="password"
            />
          </Field>

          <Field label="Confirm password" errors={errors?.passwordConfirmation}>
            <TextInput
              placeholder="Confirm your password"
              value={fields.passwordConfirmation}
              setValue={(passwordConfirmation) => updateFields({ passwordConfirmation })}
              type="password"
            />
          </Field>
        </div>

        <div>
          <Action.P className="w-full" disabled={fields.password.length === 0} type="submit">
            Continue
          </Action.P>
        </div>
      </Form>
    </FocusLayout>
  )
}

export default PasswordStep
