import Layout from '../components/LayoutNext'
import Customers from '../components/Customers'
import BookDemoHero from './Home/BookDemoHero'
import ProductIntro from './Home/ProductIntro'
import WeMakeItEasier from './Home/WeMakeItEasier'
import BookDemoCallOutBottom from './Home/BookDemoCallOutBottom'
import CaseOfSuccess from './Home/CaseOfSuccess'

const Home = () => (
  <Layout>
    <BookDemoHero />
    <Customers />
    <ProductIntro />
    <WeMakeItEasier />
    <CaseOfSuccess />
    <BookDemoCallOutBottom />
  </Layout>
)

export default Home
