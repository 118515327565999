import React from 'react'
import { useQueryParams, NumberParam, withDefault } from 'use-query-params'

const useQueryParamsOpts = { page: withDefault(NumberParam, 1) }

const usePage = () => {
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)
  const canonicalPage = query.page > 0 ? query.page : 1

  const setPage = (newPage: number | undefined) => updateQuery({ page: newPage })

  React.useEffect(() => {
    if (query.page !== canonicalPage) setPage(canonicalPage)
  }, [])

  return [canonicalPage, setPage] as const
}

export default usePage
