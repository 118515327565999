const About = () => (
  <>
    <h3 className="mt-6 text-left text-2xl leading-9 font-extrabold text-gray-900">
      What is Gearflow?
    </h3>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      Gearflow is modernizing parts commerce to make it easier for equipment owners and their fleet
      teams to work more efficiently with suppliers they can trust.
    </p>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      We are the only parts commerce platform that gives your mixed fleet customers access to the
      vendors, communication, and reporting that they need to keep their equipment running and their
      jobs profitable.
    </p>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      You can think of us as your parts sales enablement partner. We built Gearflow to help
      suppliers like yourself use technology to grow your parts sales.
    </p>
    <div className="container">
      <div className="mt-5 md:mt-6 md:text-left max-w-6xl mx-auto">
        <p className="aspect-w-16 aspect-h-9">
          <iframe
            className="rounded-lg"
            src="https://www.youtube-nocookie.com/embed/csqJWUdTc8s"
            title="What is Gearflow?"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </p>
      </div>
    </div>
    <h3 className="mt-6 text-left text-2xl leading-9 font-extrabold text-gray-900">
      What Gearflow is not &hellip;
    </h3>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      We are <span className="font-bold">not</span> here to disrupt the relationship that exists
      between you and your current customers.
    </p>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      In fact, we are working to strengthen it by allowing your customers to streamline their order
      management through centralized processes and provide real-time cost tracking across their
      fleet.
    </p>
    <h3 className="mt-6 text-left text-2xl leading-9 font-extrabold text-gray-900">
      What do I need to do next?
    </h3>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      Register for your free dealer account using the form. After signing up, we&rsquo;ll reach out
      to you directly with the next steps in your onboarding process. If you have any questions,
      email us at suppliers@gearflow.com.
    </p>
  </>
)

export default About
