import classNames from 'classnames'
import { motion } from 'framer-motion'
import ajax from './Customers/ajax.png'
import branchCivil from './Customers/branch_civil.png'
import cccGroup from './Customers/ccc-group.jpeg'
import clear from './Customers/clear.png'
import manhattan from './Customers/manhattan-road.png'
import hawkins from './Customers/hawkins.png'
import LayoutContainer from './LayoutNext/LayoutContainer'

const CustomerImage = ({
  image,
  caption,
  imgClassName,
}: {
  image: string
  caption: string
  imgClassName?: string
}) => (
  <motion.li
    variants={{
      visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'spring', stiffness: 300, damping: 24 },
      },
      hidden: { opacity: 0, scale: 0.7, transition: { duration: 0.2 } },
    }}
    className="h-24 md:h-36"
  >
    <motion.figure className="h-full w-full flex items-center p-4">
      <img
        src={image}
        alt={caption}
        className={classNames('h-full w-full max-h-[4.5rem] object-contain', imgClassName)}
      />

      <figcaption className="hidden sr-only">{caption}</figcaption>
    </motion.figure>
  </motion.li>
)

const Customers = ({ className }: { className?: string }) => (
  <LayoutContainer className={className}>
    <section className="pb-8">
      <h4 className="text-gray-500 pb-8 text-center text-base sm:text-xl">
        Trusted by leading construction fleets
      </h4>

      <motion.ul
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, delayChildren: 0.1, staggerChildren: 0.2 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-8"
      >
        <CustomerImage image={ajax} caption="Ajax Paving Industries of Florida" />
        <CustomerImage image={hawkins} caption="Hawkins Construction Company" />
        <CustomerImage image={cccGroup} caption="CCC Group" />
        <CustomerImage image={clear} caption="Clear Companies" />
        <CustomerImage image={manhattan} caption="Manhattan Road & Bridge" />
        <CustomerImage image={branchCivil} caption="Branch Civil" />
      </motion.ul>
    </section>
  </LayoutContainer>
)

export default Customers
