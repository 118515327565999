import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { ReactNode } from 'react'
import { cn } from '../modules/utils'
import Label from './Label'

const Error = ({ children }: { children: ReactNode }) => (
  <div className="flex gap-1 items-center">
    <ExclamationCircleIcon
      className="block h-4 w-4 flex-shrink-0 text-red-500"
      aria-hidden="true"
    />

    <span className="text-sm text-red-500 leading-4">{children}</span>
  </div>
)

const Field = ({
  className,
  label,
  tooltip,
  desc,
  errors,
  children,
  htmlFor = '',
  disabled = false,
}: {
  className?: string
  label?: ReactNode
  tooltip?: JSX.Element
  desc?: string | JSX.Element
  errors?: string | string[] | null
  htmlFor?: string
  disabled?: boolean
  children: ReactNode
}) => (
  <div className={cn('space-y-1', className)}>
    {label && (
      <div className="flex">
        <Label disabled={disabled} htmlFor={htmlFor}>
          {label}
        </Label>

        {tooltip}
      </div>
    )}

    {children}
    {desc && <div className="text-sm text-gray-500">{desc}</div>}
    {errors && <Error>{[errors].flat().join(' / ')}</Error>}
  </div>
)

export default Field
