import useGoogleAuthUrl from '@/gf/hooks/useGoogleAuthUrl'
import { SsoLinkType, useGoogleLinkLazyQuery, useSsoSignInMutation } from '@/retail/_gen/gql'
import useGqlClient from '@/retail/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import useSignUp from '../SignUp/useSignUp'

const linkType = SsoLinkType.Google

const useContinueWithGoogle = ({
  onSignedIn,
  onNoMatch,
}: {
  onSignedIn: (returnPath: string) => void
  onNoMatch: () => void
}) => {
  const client = useGqlClient()
  const location = useLocation()
  const [query, setQuery] = useQueryParams({ 'link-type': StringParam, 'link-id': StringParam })
  const [linkId, setLinkId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [signIn] = useSsoSignInMutation({ client: useGqlClient() })
  const [queryGoogleLink] = useGoogleLinkLazyQuery({ client })
  const { updateFields, reset } = useSignUp()
  const authUrl = useGoogleAuthUrl({ redirectTo: location.pathname })

  const maybeSignIn = async () => {
    if (!linkId) return
    const ssoSignIn = (await signIn({ variables: { linkId, linkType } })).data?.ssoSignIn

    if (ssoSignIn) {
      onSignedIn(ssoSignIn)
      return
    }

    const googleLink = (await queryGoogleLink({ variables: { linkId } })).data?.googleLink

    // TODO: this should not happen
    if (!googleLink) {
      setLoading(false)
      return
    }

    reset()

    updateFields({
      sso: { linkId, linkType, email: googleLink.me.email },
      code: `${parseInt('0xFFC1074', 16)}`,
    })

    onNoMatch()
  }

  useEffect(() => {
    if (query['link-type'] === linkType && query['link-id']) {
      setLoading(true)
      setLinkId(query['link-id'])
      setQuery({ 'link-type': undefined, 'link-id': undefined })
    }
  }, [])

  // A second useEffect is needed to avoid signIn running twice in React strict mode
  useEffect(() => {
    maybeSignIn()
  }, [linkId])

  return { authUrl, loading }
}

export default useContinueWithGoogle
