import Para from './Para'
import useConfig from '@/gf/hooks/useConfig'

import { SourceProduct } from '../types'

const SourceProductInfo = ({
  sourceProduct,
  displayStores,
}: {
  sourceProduct: SourceProduct
  displayStores: boolean
}) => {
  const { gfBaseUrl } = useConfig()

  return (
    <div className="grid gap-2">
      {displayStores &&
        sourceProduct?.products.map((product) => (
          <span className="flex items-center cursor-pointer" key={product.id}>
            <a href={`${gfBaseUrl}/store/${product.store.slug}`} className="text-sm">
              {product.store.name}
            </a>
          </span>
        ))}

      {sourceProduct.mpn && (
        <h4 className="text-sm">
          <span className="text-gray-500">Part Number:</span> {sourceProduct.mpn}
        </h4>
      )}

      {sourceProduct.altMpn && (
        <h3 className="text-sm break-words">
          <span className="text-gray-500">Alternative Part Number(s):</span> {sourceProduct.altMpn}
        </h3>
      )}

      <Para dangerouslySetInnerHTML={{ __html: sourceProduct.shortDescription }} />
    </div>
  )
}

export default SourceProductInfo
