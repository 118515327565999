import { useContext } from 'react'
import ConfigContext from '../contexts/ConfigContext'

const useConfig = () => {
  const config = useContext(ConfigContext)
  if (config === undefined) throw new Error('must be used within provider')
  return config
}

export default useConfig
