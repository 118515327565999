import gearBg2 from '@/retail/components/svgs/gear_bg_2.svg'
import SiteUrls from '@/retail/modules/SiteUrls'
import { motion } from 'framer-motion'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import shp from './IntroHero/shp.png'

const IntroHero = () => (
  <LayoutContainer
    className="how-it-works-top-bg"
    style={{
      backgroundImage: `url(${gearBg2})`,
    }}
    childrenClassName="pt-16 pb-10 sm:pb-24"
  >
    <section className="flex flex-col md:flex-row gap-8 sm:gap-12 xl:gap-16 sm:items-center">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, delayChildren: 0.2, staggerChildren: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="space-y-8 md:order-2"
      >
        <hgroup className="space-y-2">
          <motion.p
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { type: 'spring', stiffness: 300, damping: 24 },
              },
              hidden: { opacity: 0, y: -20, transition: { duration: 0.5 } },
            }}
            className="block text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem] font-medium"
          >
            Quote faster,
            <br />
            <em className="italic">sell more</em>
          </motion.p>
        </hgroup>
        <motion.p
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: { opacity: 0, y: -20, transition: { duration: 0.7 } },
          }}
          className="text-base xl:text-xl"
        >
          Close more deals and grow your margins with the tool that makes your parts team seem 5x
          bigger.
        </motion.p>
        <div className="gap-x-4 gap-y-2 flex flex-col sm:flex-row items-row sm:items-center justify-start">
          <LinkButton href="/signup/sales-hub" target="_blank">
            Try It Now!
          </LinkButton>
          <LinkButton
            variation="secondary"
            href={SiteUrls.DEALER_SCHEDULE_DEMO_URL}
            target="_blank"
          >
            Book a Discovery Call
          </LinkButton>
        </div>
      </motion.div>

      <div className="relative w-full md:w-1/2 md:order-1 flex-shrink-0 rounded-md overflow-hidden">
        <img src={shp} alt="Sales Hub Pro: Your parts desk supercharged" />
      </div>
    </section>
  </LayoutContainer>
)

export default IntroHero
