import Section from './Section'

import { Product } from '../types'

const ProductStoreInfo = ({
  product,
  openShippingInfoModal,
  openReturnsAndWarrantyModal,
}: {
  product?: Product
  openShippingInfoModal: () => void
  openReturnsAndWarrantyModal: () => void
}) => (
  <Section>
    <div className="grid grid-cols-2 gap-x-4">
      <div>
        <span className="flex items-center cursor-pointer mb-1">
          <a href={`/store/${product?.store.slug}`} className="text-sm leading-5 font-bold">
            {product?.store.name}
          </a>
        </span>
        {product && (
          <span className="text-gray-500 text-sm">
            {product.store.city}, {product.store.state}
          </span>
        )}
      </div>

      <div>
        {product &&
          product.store.shipsFree &&
          (!product.shippingCost || product.shippingCost.amount === 0) && (
            <div className="flex justify-end">
              <span className="mt-2 inline-flex items-center px-3 py-0.5 rounded-md text-sm leading-5 bg-blue-100 text-blue-800">
                <span className="h-5 w-5 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      fill="#fff"
                      d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                    />
                  </svg>
                </span>{' '}
                Free Shipping!
              </span>
            </div>
          )}
      </div>

      <button
        onClick={openReturnsAndWarrantyModal}
        type="button"
        className="mt-6 text-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Returns and Warranty
      </button>
      <button
        onClick={openShippingInfoModal}
        type="button"
        className="mt-6 text-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Shipping Information
      </button>
      <div />
    </div>
  </Section>
)

export default ProductStoreInfo
