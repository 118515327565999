import { cn } from '../modules/utils'

const SpinnerSmall = ({ className }: { className?: string | undefined }) => (
  <div
    className={cn(
      'animate-spin-slow border-t-gearflow inline-block rounded-full border-4 border-t-4 border-gray-100 h-5 w-5 mx-auto',
      className
    )}
  />
)

export default SpinnerSmall
