import { gql, useQuery, QueryHookOptions } from '@apollo/client'

interface DealerInvite {
  id: string
  email: string
  acceptedAt: string | null
  storeId: string | null
}

interface Data {
  dealerInvite: DealerInvite
}

interface Variables {
  id: string
}

const query = gql`
  query FetchDealerInvite($id: ID!) {
    dealerInvite(id: $id) {
      id
      email
      acceptedAt
      storeId
    }
  }
`

const useFetchDealerInvite = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(query, options)

export default useFetchDealerInvite
