import { Route, Routes, useParams } from 'react-router-dom'
import { App } from '../_gen/gql'
import DetailsStep from './SignUp/DetailsStep'
import PasswordStep from './SignUp/PasswordStep'
import Start from './SignUp/Start'

const SignUp = () => {
  const app = useParams().app === 'parts-hub' ? App.PartsHub : App.SalesHub

  return (
    <Routes>
      <Route path="*" element={<Start app={app} />} />
      <Route path="password" element={<PasswordStep app={app} />} />
      <Route path="details" element={<DetailsStep app={app} />} />
    </Routes>
  )
}

export default SignUp
