import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

const deprecatedCopyText = (text: string) => {
  const el = document.createElement('input')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyText = (text: string) => {
  if (navigator.clipboard) navigator.clipboard.writeText(text)
  else deprecatedCopyText(text)
}
