import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import GoogleButton, { GoogleLogo } from '@/gf/components/GoogleButton'
import TextInput from '@/gf/components/inputs/Text'
import Link from '@/gf/components/Link'
import MicrosoftButton from '@/gf/components/MicrosoftButton'
import MicrosoftLogo from '@/gf/components/MicrosoftLogo'
import Spinner from '@/gf/components/Spinner'
import {
  SsoLinkType,
  useExistingUserLazyQuery,
  useUnclaimedUserSignUpAttemptedMutation,
} from '@/retail/_gen/gql'
import useGqlClient from '@/retail/hooks/useGqlClient'
import useSession from '@/retail/hooks/useSession'
import omit from 'lodash/omit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useContinueWithGoogle from '../GoogleAuth/useContinue'
import OrDivider from '../Microsoft/OrDivider'
import useContinueWithMicrosoft from '../Microsoft/useContinue'
import { popReturnPath } from '../SignIn/Form'
import useSignUp from './useSignUp'

const emailRegex = /^[^\s]+@[^\s]+$/

const EmailStep = () => {
  const session = useSession()
  const client = useGqlClient()
  const navigate = useNavigate()
  const [goTo, setGoTo] = useState<string>()
  const [submitting, setSubmitting] = useState(false)
  const queryExistingUser = useExistingUserLazyQuery({ client })[0]
  const [unclaimedUserSignUpAttempted] = useUnclaimedUserSignUpAttemptedMutation({ client })
  const { fields, errors, updateFields, setErrors } = useSignUp()

  const args = {
    onSignedIn: (returnPath: string) => setGoTo(popReturnPath() || returnPath),
    onNoMatch: () => setGoTo('details'),
  }

  const microsoft = useContinueWithMicrosoft(args)
  const google = useContinueWithGoogle(args)

  useEffect(() => {
    if (goTo)
      if (goTo.startsWith('http')) window.location.href = goTo
      else navigate(goTo)
  }, [goTo])

  if (microsoft.loading || google.loading) return <Spinner />

  const submit = async () => {
    setSubmitting(true)
    const rest = omit(errors, ['email'])
    const newErrors = !emailRegex.test(fields.email) ? { email: ['Email is invalid'] } : {}
    setErrors({ ...rest, ...newErrors })

    if (!newErrors.email) {
      const { data } = await queryExistingUser({ variables: { email: fields.email } })
      const existingUser = data?.user

      if (existingUser) {
        if (existingUser.claimed) {
          setGoTo(`/login?email=${encodeURIComponent(fields.email)}&note=email-taken`)
        } else {
          await unclaimedUserSignUpAttempted({ variables: { userId: existingUser.id } })
          setGoTo('unclaimed')
        }
      } else {
        setGoTo('password')
      }
    } else {
      setSubmitting(false)
    }
  }

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        {fields.sso?.linkType === SsoLinkType.Microsoft && (
          <Field label="Microsoft account" errors={errors?.email}>
            <div className="flex gap-2 items-center">
              <MicrosoftLogo className="w-5 h-5" />
              <span className="grow">{fields.sso.email}</span>
              <Link.P to="details">Continue</Link.P>
            </div>
          </Field>
        )}

        {fields.sso?.linkType === SsoLinkType.Google && (
          <Field label="Google account" errors={errors?.email}>
            <div className="flex gap-2 items-center">
              <GoogleLogo className="w-5 h-5" />
              <span className="grow">{fields.sso.email}</span>
              <Link.P to="details">Continue</Link.P>
            </div>
          </Field>
        )}

        {!fields.sso && (
          <>
            <MicrosoftButton className="w-full" href={microsoft.authUrl} />

            {session?.featureFlags.googleSignIn && (
              <GoogleButton className="w-full" href={google.authUrl} />
            )}
          </>
        )}
      </div>

      {fields.sso ? (
        <div>
          <Action.T onClick={() => updateFields({ sso: null })} className="text-gray-500 text-sm">
            Sign up with email &amp; password instead
          </Action.T>
        </div>
      ) : (
        <>
          <OrDivider />

          <Form onSubmit={submit}>
            <Field label="Email" errors={errors?.email}>
              <div className="flex flex-col gap-y-4 items-stretch">
                <TextInput
                  value={fields.email}
                  setValue={(email) => updateFields({ email })}
                  placeholder="Your email address"
                />

                <Action.P
                  className="w-full"
                  disabled={!fields.email}
                  performing={submitting}
                  type="submit"
                >
                  Continue
                </Action.P>
              </div>
            </Field>
          </Form>
        </>
      )}
    </div>
  )
}

export default EmailStep
