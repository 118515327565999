import { Fragment } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

type IconProps = { width?: string; height?: string }

const ClipboardIcon = ({ width, height }: IconProps) => (
  <svg
    width={width ?? '24'}
    height={height ?? '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.5H7C6.46957 5.5 5.96086 5.71071 5.58579 6.08579C5.21071 6.46086 5 6.96957 5 7.5V19.5C5 20.0304 5.21071 20.5391 5.58579 20.9142C5.96086 21.2893 6.46957 21.5 7 21.5H17C17.5304 21.5 18.0391 21.2893 18.4142 20.9142C18.7893 20.5391 19 20.0304 19 19.5V7.5C19 6.96957 18.7893 6.46086 18.4142 6.08579C18.0391 5.71071 17.5304 5.5 17 5.5H15M9 5.5C9 6.03043 9.21071 6.53914 9.58579 6.91421C9.96086 7.28929 10.4696 7.5 11 7.5H13C13.5304 7.5 14.0391 7.28929 14.4142 6.91421C14.7893 6.53914 15 6.03043 15 5.5M9 5.5C9 4.96957 9.21071 4.46086 9.58579 4.08579C9.96086 3.71071 10.4696 3.5 11 3.5H13C13.5304 3.5 14.0391 3.71071 14.4142 4.08579C14.7893 4.46086 15 4.96957 15 5.5M12 12.5H15M12 16.5H15M9 12.5H9.01M9 16.5H9.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const TruckIcon = ({ width, height }: IconProps) => (
  <svg
    width={width ?? '24'}
    height={height ?? '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.41421 18.9142C8.78929 18.5391 9 18.0304 9 17.5C9 16.9696 8.78929 16.4609 8.41421 16.0858C8.03914 15.7107 7.53043 15.5 7 15.5C6.46957 15.5 5.96086 15.7107 5.58579 16.0858C5.21071 16.4609 5 16.9696 5 17.5C5 18.0304 5.21071 18.5391 5.58579 18.9142C5.96086 19.2893 6.46957 19.5 7 19.5C7.53043 19.5 8.03914 19.2893 8.41421 18.9142Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M18.4142 18.9142C18.7893 18.5391 19 18.0304 19 17.5C19 16.9696 18.7893 16.4609 18.4142 16.0858C18.0391 15.7107 17.5304 15.5 17 15.5C16.4696 15.5 15.9609 15.7107 15.5858 16.0858C15.2107 16.4609 15 16.9696 15 17.5C15 18.0304 15.2107 18.5391 15.5858 18.9142C15.9609 19.2893 16.4696 19.5 17 19.5C17.5304 19.5 18.0391 19.2893 18.4142 18.9142Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M13 16.5V6.5C13 6.23478 12.8946 5.98043 12.7071 5.79289C12.5196 5.60536 12.2652 5.5 12 5.5H4C3.73478 5.5 3.48043 5.60536 3.29289 5.79289C3.10536 5.98043 3 6.23478 3 6.5V16.5C3 16.7652 3.10536 17.0196 3.29289 17.2071C3.48043 17.3946 3.73478 17.5 4 17.5H5M13 16.5C13 16.7652 12.8946 17.0196 12.7071 17.2071C12.5196 17.3946 12.2652 17.5 12 17.5H9M13 16.5V8.5C13 8.23478 13.1054 7.98043 13.2929 7.79289C13.4804 7.60536 13.7348 7.5 14 7.5H16.586C16.8512 7.50006 17.1055 7.60545 17.293 7.793L20.707 11.207C20.8946 11.3945 20.9999 11.6488 21 11.914V16.5C21 16.7652 20.8946 17.0196 20.7071 17.2071C20.5196 17.3946 20.2652 17.5 20 17.5H19M13 16.5C13 16.7652 13.1054 17.0196 13.2929 17.2071C13.4804 17.3946 13.7348 17.5 14 17.5H15M5 17.5C5 18.0304 5.21071 18.5391 5.58579 18.9142C5.96086 19.2893 6.46957 19.5 7 19.5C7.53043 19.5 8.03914 19.2893 8.41421 18.9142C8.78929 18.5391 9 18.0304 9 17.5M5 17.5C5 16.9696 5.21071 16.4609 5.58579 16.0858C5.96086 15.7107 6.46957 15.5 7 15.5C7.53043 15.5 8.03914 15.7107 8.41421 16.0858C8.78929 16.4609 9 16.9696 9 17.5M19 17.5C19 18.0304 18.7893 18.5391 18.4142 18.9142C18.0391 19.2893 17.5304 19.5 17 19.5C16.4696 19.5 15.9609 19.2893 15.5858 18.9142C15.2107 18.5391 15 18.0304 15 17.5M19 17.5C19 16.9696 18.7893 16.4609 18.4142 16.0858C18.0391 15.7107 17.5304 15.5 17 15.5C16.4696 15.5 15.9609 15.7107 15.5858 16.0858C15.2107 16.4609 15 16.9696 15 17.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const MobileIcon = ({ width, height }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? '24'}
    height={height ?? '25'}
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M12 18.5H12.01M8 21.5H16C16.5304 21.5 17.0391 21.2893 17.4142 20.9142C17.7893 20.5391 18 20.0304 18 19.5V5.5C18 4.96957 17.7893 4.46086 17.4142 4.08579C17.0391 3.71071 16.5304 3.5 16 3.5H8C7.46957 3.5 6.96086 3.71071 6.58579 4.08579C6.21071 4.46086 6 4.96957 6 5.5V19.5C6 20.0304 6.21071 20.5391 6.58579 20.9142C6.96086 21.2893 7.46957 21.5 8 21.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const steps = [{ icon: ClipboardIcon }, { icon: TruckIcon }, { icon: MobileIcon }]

const Step = ({
  icon: Icon,
  state,
}: {
  icon: (props: IconProps) => JSX.Element
  state: 'complete' | 'incomplete' | 'active'
}) => (
  <motion.span
    className={classNames(
      'rounded-full flex items-center justify-center',
      state === 'active' ? 'text-white bg-orange-500 w-12 h-12' : 'h-8 w-8',
      state === 'incomplete' && 'text-gray-500 bg-gray-200',
      state === 'complete' && 'text-orange-500 border-2 border-orange-500 border-opacity-25'
    )}
    initial="hidden"
    whileInView={state === 'active' ? 'visible' : undefined}
    viewport={{ once: true }}
    variants={{
      visible: {
        scale: 1,
        x: 0,
        skewX: 0,
      },
      hidden: {
        scale: state === 'active' ? 0.3 : 1,
        x: state === 'active' ? -60 : 0,
        skewX: state === 'active' ? -20 : 0,
      },
    }}
  >
    <Icon
      width={state === 'active' ? '32' : undefined}
      height={state === 'active' ? '32' : undefined}
    />
  </motion.span>
)

const ProgressBar = ({ activeIndex }: { activeIndex: number }) => (
  <div className="hidden sm:flex items-center flex-shrink-0" aria-hidden="true">
    {steps.map((s, i) => (
      <Fragment key={s.icon.name}>
        {i > 0 && (
          <span
            className={classNames(
              'w-2 border-2 ',
              activeIndex < i ? 'border-gray-200' : 'border-orange-500'
            )}
          />
        )}
        <Step
          icon={s.icon}
          state={activeIndex > i ? 'complete' : activeIndex < i ? 'incomplete' : 'active'}
        />
      </Fragment>
    ))}
  </div>
)

export default ProgressBar
