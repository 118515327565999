import useConfig from '@/gf/hooks/useConfig'
import useSession from '@/retail/hooks/useSession'
import SignOutLink from './SignOutLink'
import { CogIcon, HomeIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

const Section = ({ mobile, halfWidth = false, children, className = '' }) => (
  <div
    className={classNames(
      mobile ? 'rounded-xl bg-white shadow-md' : 'p-3',
      halfWidth ? 'w-1/2' : 'w-full',
      className
    )}
  >
    {children}
  </div>
)

const UserMenuItems = ({ mobile = false }: { mobile?: boolean }) => {
  const config = useConfig()
  const session = useSession()
  const user = session?.user

  if (!user) {
    return null
  }

  const itemClassName = (className = '') =>
    twMerge(
      mobile
        ? 'block text-lg leading-7 p-4 border-b last:border-0 font-medium'
        : 'block p-2 hover:bg-gray-50 flex items-center gap-x-1.5 rounded-md leading-6',
      className
    )

  return (
    <div>
      <div className={mobile ? 'space-y-4' : 'flex divide-x-1 border-b'}>
        <Section mobile={mobile} halfWidth={!mobile && !!user.store}>
          <a href={`${config.buyersUrl}/`} className={itemClassName('font-medium')}>
            <CogIcon className="text-orange-500 h-6 w-6 stroke-2 inline-block" /> Parts Hub
          </a>
          <a href={`${config.buyersUrl}/rfqs`} className={itemClassName()}>
            Requests
          </a>
          <a href={`${config.buyersUrl}/orders`} className={itemClassName()}>
            Orders
          </a>
          <a href={`${config.buyersUrl}/reporting`} className={itemClassName()}>
            Reports
          </a>
        </Section>
        {user.store && (
          <Section mobile={mobile} halfWidth={!mobile && !!user.store}>
            <>
              <a href={`${config.suppliersUrl}/`} className={itemClassName('font-medium')}>
                <HomeIcon className="h-6 w-6 stroke-2 text-orange-500 inline-block" /> Sales Hub
              </a>
              <a href={`${config.suppliersUrl}/rfqs`} className={itemClassName()}>
                Requests
              </a>
              <a href={`${config.suppliersUrl}/metrics`} className={itemClassName()}>
                Reports
              </a>
            </>
          </Section>
        )}
      </div>

      <Section mobile={mobile} className={mobile ? 'mt-4' : ''}>
        <SignOutLink className={itemClassName()} />
      </Section>
    </div>
  )
}

export default UserMenuItems
