import Layout from '../components/LayoutNext'
import AboutBottom from './About/AboutBottom'
import AboutMiddle from './About/AboutMiddle'
import AboutTop from './About/AboutTop'

const About = () => {
  return (
    <Layout>
      <AboutTop />
      <AboutMiddle />
      <AboutBottom />
    </Layout>
  )
}

export default About
