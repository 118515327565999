import React from 'react'
import { Link, useParams } from 'react-router-dom'

import useToggle from '@/gf/hooks/useToggle'
import useCategories from '../hooks/useCategories'
import useProduct from '../hooks/useProduct'
import CategoryTree from '../modules/CategoryTree'
import ProductM from '../modules/Product'

import ReturnsAndWarrantyInfoModal from '@/gf/components/ReturnsAndWarrantyInfoModal'
import ShippingInfoModal from '@/gf/components/ShippingInfoModal'
import CanonicalRedirect from '../components/CanonicalRedirect'
import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import MoreSupplierProducts from '../components/MoreSupplierProducts'
import AddToCart from '../components/ProductAddToCart'
import StoreInfo from '../components/ProductStoreInfo'
import RelatedProducts from '../components/RelatedProducts'
import Section, { CollapsibleSection } from '../components/Section'
import RightArrow from '../components/svgs/RightArrow'

const Crumb = ({ category }: { category: { slugPath: string; name: string } }) => (
  <>
    <Link to={`/c/${category.slugPath}`}>{category.name}</Link>
    <RightArrow />
  </>
)

const StoreProduct = () => {
  const slugPath = (useParams<{ ['*']: string }>() as { slugPath: string })['*']
  const categories = useCategories()
  const [shippingInfoModalIsOpen, shippingInfoModalToggler] = useToggle(false)
  const [returnsAndWarrantyModalIsOpen, returnsAndWarrantyModalToggler] = useToggle(false)
  const { data } = useProduct({ slugPath })
  const product = data?.product

  const category = React.useMemo(
    () =>
      product &&
      categories &&
      product.category &&
      CategoryTree.findById(categories, product.category.id),
    [!categories, product?.id]
  )

  if (product === null) {
    return (
      <Layout>
        <section className="grid grid-cols-4 gap-4 px-4 sm:px-0 z-0 pb-4">
          <section className="col-span-4">
            <div className="max-w-3xl mx-auto">
              <div className="p-16 text-center">
                <h1 className="text-2xl">Sorry, the page you are looking for does not exist.</h1>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
  }

  return (
    <Layout>
      <Container className="py-4">
        {product && <CanonicalRedirect path={`/itm/${product.slugPath}`} />}

        {category && (
          <div className="flex mb-4">
            {category.ascendants.map((c) => (
              <Crumb category={c} key={c.id} />
            ))}
            <Crumb category={category} />
            {product?.name}
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 auto-cols-max">
            <Section className="lg:col-span-3">
              {product && (
                <img
                  src={ProductM.primaryImageURL(product, 'main')}
                  alt={product?.name}
                  className="mx-auto"
                  height="360"
                  width="560"
                />
              )}
            </Section>

            <div className="lg:col-span-2 h-full">
              <div className="grid grid-cols-1 gap-y-4 h-full">
                <AddToCart product={product} />

                <StoreInfo
                  product={product}
                  openShippingInfoModal={shippingInfoModalToggler.on}
                  openReturnsAndWarrantyModal={returnsAndWarrantyModalToggler.on}
                />
              </div>
            </div>
          </div>

          {product && product.fitsWithMachines.length > 0 && (
            <CollapsibleSection title="Fits With">
              {product.fitsWithMachines.map((machine) => (
                <span
                  key={`${machine.year} ${machine.make} ${machine.model}`}
                  className="inline-flex items-center py-0.5 px-2 rounded text-sm bg-yellow-100 text-yellow-700"
                >
                  {[machine.year, machine.make, machine.model].filter(Boolean).join(' ')}
                </span>
              ))}
            </CollapsibleSection>
          )}

          {product && product.description && product.description.length > 0 && (
            <Section title="Description">
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
            </Section>
          )}

          <RelatedProducts product={product} category={category} />
          <MoreSupplierProducts product={product} />
        </div>
      </Container>

      <ShippingInfoModal
        open={shippingInfoModalIsOpen}
        onClose={shippingInfoModalToggler.off}
        shippingInfo={product?.store.shippingInfo ?? ''}
      />
      <ReturnsAndWarrantyInfoModal
        open={returnsAndWarrantyModalIsOpen}
        onClose={returnsAndWarrantyModalToggler.off}
        returnsAndWarrantyInfo={product?.store.returnPolicy ?? ''}
      />
    </Layout>
  )
}

export default StoreProduct
