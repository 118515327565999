import { Disclosure } from '@headlessui/react'
import {
  BellIcon,
  ChatAltIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ClipboardListIcon,
  CurrencyDollarIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'

import A from '@/gf/components/A'
import header from './DealerSolutions/header.png'
import screenshot from './DealerSolutions/rfq-details-supplier-screenshot.png'
import support from './support.jpg'

import Layout from '../components/LayoutNext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const features = [
  {
    name: 'Easy quoting',
    description:
      'Receive inbound parts requests with machine info and submit quotes for the brands and categories you represent.',
    icon: CheckCircleIcon,
  },
  {
    name: 'OEM listings',
    description:
      'Access part listings from the OEMs on Gearflow that you represent without having to manage the catalog.',
    icon: ClipboardListIcon,
  },
  {
    name: 'Risk-free payment terms',
    description:
      'Plug into Gearflow Payments and accept a range of payment methods that make it easier to do business.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Real-time messaging',
    description:
      'Track all internal communications and customer requests in one place for faster response times.',
    icon: ChatAltIcon,
  },
  {
    name: 'Automated notifications',
    description:
      'Keep your customers up to date on the status of every order. Eliminate the daily flood of calls seeking tracking numbers or availability.',
    icon: BellIcon,
  },
  {
    name: 'Performance reporting',
    description:
      'Capitalize on live insights into your parts sales, down to the customer, sales rep, and product, so you can easily identify opportunities to scale.',
    icon: PresentationChartLineIcon,
  },
]

const DealerSolutions = () => (
  <Layout metaTitle="Construction Equipment Parts and Attachments | Gearflow">
    {/* Hero section */}
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <img className="h-full w-full object-cover" src={header} alt="" />
            <div className="absolute inset-0 bg-gradient-to-r from-gearflow-dark to-gearflow-light mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">The end-to-end parts ordering</span>
              <span className="block text-white">experience your customers want</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              Make it easier for equipment owners to maximize uptime and run more efficient
              operations.
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="flex justify-center">
                <a
                  href="https://landing-page.gearflow.com/request-demo-dealers"
                  className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gearflow bg-white sm:px-8"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
            Grow margins together
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Transform into a true uptime partner
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Own the relationship with your mixed fleet customers by delivering everything they need
            for streamlined parts ordering in one place.
          </p>
        </div>
        <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src={screenshot}
            alt=""
          />
        </div>
      </div>
    </div>

    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
          Seamless service
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Solutions built for better support
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Amplify your parts expertise with tools that accelerate your ability to serve your
          customers &nbsp; ... &nbsp; so you both succeed.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-gearflow-dark to-gearflow-light rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* <div className="pb-16 bg-gradient-to-r from-gearflow-dark to-gearflow-light lg:pb-0 lg:z-10 lg:relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="relative lg:-my-8">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
              <img
                className="object-cover lg:h-full lg:w-full"
                src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
            <blockquote>
              <div>
                <svg
                  className="h-12 w-12 text-white opacity-25"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="mt-6 text-2xl font-medium text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed urna nulla vitae
                  laoreet augue. Amet feugiat est integer dolor auctor adipiscing nunc urna, sit.
                </p>
              </div>
              <footer className="mt-6">
                <p className="text-base font-medium text-white">Judith Black</p>
                <p className="text-base font-medium text-cyan-100">CEO at PureInsights</p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div> */}

    <section id="faqs" aria-labelledby="faq-heading" className="bg-white">
      <div className="max-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              <Disclosure as="div" key="How much does it cost to get started?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          How much does it cost to get started?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Joining the Gearflow Supplier Network or responding to parts requests is
                        completely FREE! At this time, we do not charge any fees for your storefront
                        setup, product listings, or parts quotes.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="Is there a limit to how many products I can upload?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Is there a limit to how many products I can upload or parts requests I can
                          respond to?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Nope! You can list as many parts or submit as many quotes as you&#39;d like,
                        free of charge.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="Do I need to have a storefront to join Gearflow?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Do I need to have a storefront to join Gearflow?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        You do not need product listings live on Gearflow in order to become part of
                        the Gearflow Supplier Network (though suppliers with storefronts have
                        greater potential for sales revenue overall!). You can choose to participate
                        by only responding to parts requests (RFQs) from new and current customers
                        who are looking for the parts you sell. We&#39;ll email you parts requests,
                        and you can quote them out with our platform&#39;s easy-to-use tools.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="What are Gearflow’s fees?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          What are Gearflow&#39;s fees?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        We only charge a fee if Gearflow processes the payment for the transaction.
                        If you receive a purchase order from a current customer paying you directly
                        via invoice, there is no fee. For new customer transactions where payment is
                        processed on the Gearflow platform, the fee is 12.65% on the order total.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="How do I get paid?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">How do I get paid?</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        If you participate in Gearflow Payments, you will automatically get paid out
                        directly to your bank account within 1-2 business days, once you&#39;ve
                        marked an order as shipped. Current customer transactions where the payment
                        was not processed on Gearflow will be paid by the customer directly to you
                        via invoice as normal.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure
                as="div"
                key="Does Gearflow offer any APIs into our current ecommerce platform?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Does Gearflow offer any APIs into our current ecommerce platform?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Yes, we do! Please contact us at{' '}
                        <A.T href="mailto:suppliers@gearflow.com">suppliers@gearflow.com</A.T> for
                        more details.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img className="w-full h-full object-cover" src={support} alt="" />
        <div aria-hidden="true" className="absolute inset-0" />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            Unparalleled Support.
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            We&apos;re here to help
          </p>
          <p className="mt-3 text-lg text-gray-300">
            We understand your pains because we have lived them. Our tools are designed to enhance
            the relationships that make this industry go, not replace them.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://landing-page.gearflow.com/request-demo-dealers"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Request a demo
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DealerSolutions
