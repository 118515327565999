import { AppName } from '@/types'
import { createContext } from 'react'
import Rollbar from 'rollbar'

export type Config = {
  gfBaseUrl: string
  developersUrl: string
  suppliersUrl: string
  suppliersGqlWsUrl: string
  buyersGqlWsUrl: string
  buyersUrl: string
  adminUrl: string
  dealersUrl: string
  dealersGqlWsUrl: string
  balanceDashboardBaseUrl: string
  stripeDashboardBaseUrl: string
  buyersGqlTestingUrl: string
  buyersMobileAppUrl: string
  csrfToken: string
  stripePublicKey: string
  app?: AppName
  rollbar: Rollbar.Configuration
  convenienceFeeRate: number
  googleMapsApiKey: string
}

const ConfigContext = createContext<Config | undefined>(undefined)

export default ConfigContext
