import { Provider as RollbarProvider } from '@rollbar/react'

import useConfig from '@/gf/hooks/useConfig'

const ErrorTrackerProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()

  return <RollbarProvider config={config.rollbar}>{children}</RollbarProvider>
}

export default ErrorTrackerProvider
