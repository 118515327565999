import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import branchCivilMachine from './BranchCivilTestimonial/branch_civil_machine.png'
import branchCivilLogo from './BranchCivilTestimonial/branch_civil_logo.png'
import bottomBg from './BranchCivilTestimonial/bottom_bg.svg'

const Quotes = () => (
  <svg width="53" height="46" viewBox="0 0 53 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.8 23.9C47.1333 24.7 49.4 26.0333 50.6 27.9C51.9333 29.6333 52.6 31.9 52.6 34.7C52.6 37.7667 51.5333 40.3667 49.4 42.5C47.4 44.5 44.7333 45.5 41.4 45.5C38.2 45.5 35.3333 44.3 32.8 41.9C30.4 39.3667 29.2 35.5667 29.2 30.5C29.2 24.9 30.6 19.5 33.4 14.3C36.2 8.96666 40.6 4.5 46.6 0.900004C48.4667 0.0999984 49.8 0.499998 50.6 2.1C51.4 3.7 50.9333 5.10001 49.2 6.3C47.2 7.5 45.3333 9.36667 43.6 11.9C41.8667 14.3 40.7333 16.6333 40.2 18.9C39.8 20.5 40 21.7 40.8 22.5C41.7333 23.3 42.7333 23.7667 43.8 23.9ZM14.8 23.9C18 24.7 20.2667 26.0333 21.6 27.9C22.9333 29.6333 23.6 31.9 23.6 34.7C23.6 37.7667 22.4667 40.3667 20.2 42.5C18.0667 44.5 15.4 45.5 12.2 45.5C9 45.5 6.13333 44.3 3.6 41.9C1.2 39.3667 0 35.5667 0 30.5C0 24.9 1.4 19.5 4.2 14.3C7.13333 8.96666 11.6 4.5 17.6 0.900004C19.4667 0.0999984 20.7333 0.499998 21.4 2.1C22.2 3.7 21.7333 5.10001 20 6.3C18 7.5 16.1333 9.36667 14.4 11.9C12.6667 14.3 11.6 16.6333 11.2 18.9C10.8 20.5 11 21.7 11.8 22.5C12.6 23.3 13.6 23.7667 14.8 23.9Z"
      fill="#111827"
    />
  </svg>
)

const BranchCivilTestimonial = () => (
  <LayoutContainer
    className="bg-no-repeat"
    style={{
      backgroundImage: `url(${bottomBg})`,
      backgroundPosition: 'center 101%',
      backgroundSize: '100vw',
    }}
  >
    <section className="flex flex-col sm:flex-row sm:items-center sm:justify-center gap-y-8 gap-x-16 pt-8 pb-12 sm:py-16">
      <img
        src={branchCivilMachine}
        alt="Branch Civil Machine Working"
        className="h-[15rem] sm:w-[27.56rem] sm:h-[35.5rem] object-cover rounded-[1.25rem] shadow-lg"
      />
      <blockquote className="space-y-6 sm:space-y-8 max-w-[42.4375rem]">
        <Quotes />
        <p className="text-base sm:text-xl italic">
          We&apos;re working with Gearflow to help us consolidate our parts sourcing options,
          maintenance workflows, and cost data to ultimately reduce our mechanics&apos;
          administrative time and the downtime on our equipment, as well as simplify our invoicing.
        </p>

        <p className="text-base sm:text-xl italic">
          At Branch Civil, our equipment operations are a differentiator in job profitability, and
          we&apos;re a strong believer in implementing digital tools that help us increase those
          margins.
        </p>
        <footer className="flex items-center gap-x-3">
          <div className="w-[3.5625rem] h-[4.5rem]">
            <img
              src={branchCivilLogo}
              alt="Branch Civil company logo"
              className="w-full h-full object-center"
            />
          </div>

          <div>
            <p className="text-xl font-medium">Tim Morgan</p>
            <p className="text-base">Equipment Operations Manager</p>
          </div>
        </footer>
      </blockquote>
    </section>
  </LayoutContainer>
)

export default BranchCivilTestimonial
