import CategoriesContext from '../contexts/CategoriesContext'
import useFetchCategories from '../hooks/useFetchCategories'

const CategoriesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const categories = useFetchCategories().data?.categories

  return <CategoriesContext.Provider value={categories}>{children}</CategoriesContext.Provider>
}

export default CategoriesProvider
