import nth from 'lodash/nth'

import Time from './Time'
import SourceProduct from './SourceProduct'
import ProductImage from './ProductImage'

import { Product, BaseProductPayload } from '../../types'

const fromPayload = <Payload extends BaseProductPayload>(payload: Payload) => {
  const addedAt = payload.addedAt !== undefined ? Time.fromPayload(payload.addedAt) : undefined

  const updatedAt =
    payload.updatedAt !== undefined ? Time.fromPayload(payload.updatedAt) : undefined

  const sourceProduct =
    payload.sourceProduct !== undefined
      ? SourceProduct.fromPayload(payload.sourceProduct)
      : undefined

  return { ...payload, addedAt, updatedAt, sourceProduct }
}

// Returns the marketplace URL or undefined when there is no marketplace URL (when product is offline or disabled)
const marketplaceUrl = (
  product: Pick<Product, 'enabled' | 'offline' | 'path'>,
  gfBaseUrl: string
) => (product.enabled && !product.offline ? `${gfBaseUrl}${product.path}` : undefined)

const primaryImageURL = (product: Pick<Product, 'images'>, variantName: string) =>
  ProductImage.url(nth(product.images, 0), variantName)

export default {
  fromPayload,
  marketplaceUrl,
  primaryImageURL,
}
