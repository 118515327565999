import { useState, useEffect } from 'react'
import { useRollbarPerson } from '@rollbar/react'

import SessionContext from '../contexts/SessionContext'
import useFetchSession from '../hooks/useFetchSession'

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const newSession = useFetchSession().data?.session
  const [session, setSession] = useState(newSession)
  useRollbarPerson({ email: session?.user?.email })

  useEffect(() => {
    if (newSession) setSession(newSession)
  }, [!newSession])

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

export default SessionProvider
