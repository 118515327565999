import { useMemo } from 'react'
import { DateTime, Duration } from 'luxon'
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client'
import { withScalars } from 'apollo-link-scalars'
import { buildClientSchema, IntrospectionQuery } from 'graphql'

import useConfig from '@/gf/hooks/useConfig'
import MoneyM from '@/gf/modules/Money'
import GqlClientContext from '@/retail/contexts/GqlClient'
import introspectionResult from '@/retail/_gen/introspection.json'

import { Money } from '@/types'

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery)

const typesMap = {
  NaiveDateTime: {
    serialize: (parsed: unknown) => (parsed instanceof DateTime ? parsed.toISO() : null),
    parseValue: (raw: unknown) => {
      if (!raw) return null // if for some reason we want to treat empty string as null, for example
      return DateTime.fromISO(raw as string, { zone: 'utc' }).toLocal()
    },
  },
  Money: {
    serialize: (parsed: unknown) => (parsed !== null ? MoneyM.toPayloadV2(parsed as Money) : null),
    parseValue: (raw: unknown) => {
      if (!raw) return null // if for some reason we want to treat empty string as null, for example
      return MoneyM.fromPayloadV2(raw as string)
    },
  },
  Duration: {
    serialize: (parsed: unknown) =>
      parsed instanceof Duration ? Math.round(parsed.toMillis() / 1000) : null,
    parseValue: (raw: unknown) => {
      if (!raw) return null // if for some reason we want to treat empty string as null, for example
      return Duration.fromMillis((raw as number) * 1000)
    },
  },
  Decimal: {
    serialize: (parsed: unknown) =>
      typeof parsed === 'number' ? `${parsed}` : typeof parsed === 'string' ? parsed : null,
    parseValue: (raw: unknown) => {
      if (!raw) return null // if for some reason we want to treat empty string as null, for example
      return parseFloat(raw as string)
    },
  },
}

const GqlClientProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          withScalars({ schema, typesMap }),
          new HttpLink({ uri: `${config.gfBaseUrl}/gql/next` }),
        ]),
        cache: new InMemoryCache(),
        credentials: 'include',
        defaultOptions: {
          watchQuery: { fetchPolicy: 'no-cache' },
          query: { fetchPolicy: 'no-cache' },
          mutate: { fetchPolicy: 'no-cache' },
        },
      }),
    []
  )

  return <GqlClientContext.Provider value={client}>{children}</GqlClientContext.Provider>
}

export default GqlClientProvider
