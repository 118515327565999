import useConfig from '@/gf/hooks/useConfig'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useLayoutQuery } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import Footer from './LayoutNext/Footer'
import Header from './LayoutNext/Header'

const Layout = ({ children, ...props }: { metaTitle?: string; children: React.ReactNode }) => {
  const config = useConfig()
  const location = useLocation()

  const { data } = useLayoutQuery({
    variables: { pagePath: location.pathname },
    client: useGqlClient(),
  })

  const metaTitle = data?.page?.metaTitle || props.metaTitle

  return (
    <>
      <Helmet>
        {metaTitle && <title>{`${metaTitle} | Gearflow`}</title>}
        <meta name="keywords" content="equipment, marketplace, parts, construction" />
        <meta
          name="description"
          content="Gearflow simplifies parts ordering for heavy equipment fleets. Get better parts access, communication, and reporting all in one platform. Sign up free!"
        />
        <link rel="canonical" href={`${config.gfBaseUrl}/`} />
        <style type="text/css">
          {`
            html {
              font-family: 'IBM Plex Sans', 'Lato', 'Helvetica', 'Arial', 'Inter', 'sans-serif';
            }
          `}
        </style>
      </Helmet>

      <div className="relative">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
