import LayoutContainer from './LayoutNext/LayoutContainer'

const QuestionAndAnswer = ({ question, answer }: { question: string; answer: string[] }) => (
  <section className="border-t-2 border-gray-200">
    <h3 className="text-2xl sm:text-3xl font-medium py-8">{question}</h3>

    <div className="space-y-8 pb-8">
      {answer.map((a) => (
        <p key={a} className="text-base sm:text-lg">
          {a}
        </p>
      ))}
    </div>
  </section>
)

const FAQ = ({ questions }: { questions: { question: string; answer: string[] }[] }) => (
  <LayoutContainer className="py-16">
    <section>
      <h2 className="text-3xl sm:text-5xl font-medium pb-8">Frequently Asked Questions</h2>

      {questions.map((q) => (
        <QuestionAndAnswer key={q.question} question={q.question} answer={q.answer} />
      ))}
    </section>
  </LayoutContainer>
)

export default FAQ
