import useConfig from './useConfig'

const toQueryString = (obj: Record<string, string>) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

const params = {
  client_id: '308185078139-rs41nn8bgshtnpfa7b98pvr6obhgradp.apps.googleusercontent.com',
  response_type: 'code',
  access_type: 'offline',
  include_granted_scopes: 'true',
}

const useGoogleAuthUrl = ({ redirectTo }: { redirectTo: string }) => {
  const config = useConfig()

  const query = toQueryString({
    ...params,
    redirect_uri: `${config.gfBaseUrl}/oauth/google`,
    state: redirectTo,
    scope: ['https://www.googleapis.com/auth/userinfo.email'].join(' '),
  })

  return `https://accounts.google.com/o/oauth2/v2/auth?${query}`
}

export default useGoogleAuthUrl
