import { forwardRef } from 'react'
import classNames from 'classnames'

import BaseTextInput from './inputs/Text'

type Props = Parameters<typeof BaseTextInput>[0] & { leftIconPad?: boolean }

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ leftIconPad = false, className, ...props }, ref) => (
    <BaseTextInput
      {...props}
      className={classNames(leftIconPad ? 'pl-7' : '', className)}
      ref={ref}
    />
  )
)

export default TextInput
