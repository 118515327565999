import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

const solutions = [
  {
    name: 'Equipment Owners',
    href: '/solutions/buyer',
  },
  {
    name: 'Dealers',
    href: '/solutions/dealer',
  },
  {
    name: 'Manufacturers',
    href: '/solutions/manufacturer',
  },
  {
    name: 'Distributors',
    href: '/solutions/distributor',
  },
]

const WhoWeServe = () => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? 'text-gray-900' : 'text-gray-500',
            'group inline-flex items-center rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 whitespace-nowrap'
          )}
        >
          <span>Who We Serve</span>
          <ChevronDownIcon
            className={classNames(
              open ? 'text-gray-600' : 'text-gray-400',
              'ml-2 h-5 w-5 group-hover:text-gray-500'
            )}
            aria-hidden="true"
          />
        </Popover.Button>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 left-0 transform -translate-x-8 mt-3 px-2 w-screen max-w-xs">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative bg-white py-6">
                {solutions.map((item) => (
                  <a key={item.name} href={item.href} className="block px-8 py-2 hover:bg-gray-100">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
)

export default WhoWeServe
