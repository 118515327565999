import keyBy from 'lodash/keyBy'

import { CurrencyCode, Currency } from '../../types'

const currencies: Currency[] = [{ code: 'USD' }]

const currencyByCode = keyBy(currencies, 'code')

// public

const fromCode = (code: CurrencyCode) => currencyByCode[code]

export default { fromCode }
