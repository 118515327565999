import A from '@/gf/components/A'
import MicrosoftLogo from '@/gf/components/MicrosoftLogo'
import { cn } from '@/gf/modules/utils'

type Props = Parameters<typeof A.S>[0]

const MicrosoftButton = ({ children, className, ...props }: Props) => (
  <A.S
    className={cn('flex gap-x-2 items-center justify-center font-medium text-gray-700', className)}
    {...props}
  >
    <MicrosoftLogo className="w-5 h-5 inline-flex shrink-0" />
    <span className="grow-0">{children || 'Continue with Microsoft'}</span>
  </A.S>
)

export default MicrosoftButton
