import ContactCallout from '@/retail/components/ContactCallout'
import BranchCivilTestimonial from '../PartsHub/BranchCivilTestimonial'

import SiteUrls from '@/retail/modules/SiteUrls'
import { PartsHubPricing } from '@/retail/components/PricingSection'
import Navigation from './Navigation'

const PartsHub = () => (
  <>
    <PartsHubPricing
      title="Pricing"
      navigation={<Navigation selectedLink="parts-hub" />}
      showLearnMoreLink
    />

    <BranchCivilTestimonial />

    <ContactCallout
      scheduleDemoUrl={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
      text="Ready to explore the “final frontier” of fleet management? We'll guide you every step of the way."
    />
  </>
)

export default PartsHub
