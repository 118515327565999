import React from 'react'
import { useNavigate } from 'react-router-dom'
import type { NavigateFunction } from 'react-router-dom'

const StableNavigateContext = React.createContext<React.MutableRefObject<NavigateFunction> | null>(
  null
)

const StableNavigateProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const navigateRef = React.useRef(navigate)

  return (
    <StableNavigateContext.Provider value={navigateRef}>{children}</StableNavigateContext.Provider>
  )
}

export default StableNavigateProvider
