const HomeIcon = () => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 32.5L13.3333 27.1667M13.3333 27.1667L32 8.5L50.6667 27.1667M13.3333 27.1667V53.8333C13.3333 54.5406 13.6143 55.2189 14.1144 55.719C14.6145 56.2191 15.2928 56.5 16 56.5H24M50.6667 27.1667L56 32.5M50.6667 27.1667V53.8333C50.6667 54.5406 50.3857 55.2189 49.8856 55.719C49.3855 56.2191 48.7072 56.5 48 56.5H40M24 56.5C24.7072 56.5 25.3855 56.2191 25.8856 55.719C26.3857 55.2189 26.6667 54.5406 26.6667 53.8333V43.1667C26.6667 42.4594 26.9476 41.7811 27.4477 41.2811C27.9478 40.781 28.6261 40.5 29.3333 40.5H34.6667C35.3739 40.5 36.0522 40.781 36.5523 41.2811C37.0524 41.7811 37.3333 42.4594 37.3333 43.1667V53.8333C37.3333 54.5406 37.6143 55.2189 38.1144 55.719C38.6145 56.2191 39.2928 56.5 40 56.5M24 56.5H40"
      stroke="#111827"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon
