import classNames from 'classnames'

const Spinner = ({
  className,
  size = 'md',
  color = 'orange',
}: {
  className?: string | undefined
  size?: 'xs' | 'sm' | 'md'
  color?: 'orange' | 'blue'
}) => (
  <div
    className={classNames(
      'animate-spin-slow rounded-full border-gray-200 mx-auto',
      size === 'xs' && 'border-4 border-t-4 h-4 w-4',
      size === 'sm' && 'border-4 border-t-4 h-8 w-8',
      size === 'md' && 'border-8 border-t-8 h-16 w-16',
      color === 'orange' ? 'border-t-gearflow' : 'border-t-blue-600',
      className
    )}
  />
)

export default Spinner
