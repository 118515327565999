import { useQuery, gql } from '@apollo/client'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Container from '../components/Layout/Container'

const Brands = () => {
  const { data } = useQuery<{
    brands: {
      name: string
      slug: string
    }[]
  }>(gql`
    query FetchBrands {
      brands {
        slug
        name
      }
    }
  `)

  const brands = data?.brands || []

  return (
    <>
      <Helmet>
        <title>A-Z Brands | Gearflow</title>
        <meta
          name="description"
          content="A list of all the brands we have in stock at gearflow.com"
        />
      </Helmet>
      <Layout>
        <Container className="py-4">
          <div className="mt-2 mb-4 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                A-Z List of Brands
              </h1>
              <p className="mt-2 flex items-center text-base leading-5 text-gray-600 sm:mr-6">
                Looking for a specific brand? Here is a list of all the brands we currently have in
                stock.
              </p>
            </div>
          </div>

          <Section className="sm:rounded-md">
            <ul className="grid grid-cols-3 md:grid-cols-4 gap-2">
              {brands.map((brand) => (
                <li className="list-none" key={brand.slug}>
                  <a
                    href={`/brand/${brand.slug}`}
                    className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >
                    <div className="px-4 py-2 flex items-center sm:px-6">
                      <div className="leading-6">{brand.name}</div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </Section>
        </Container>
      </Layout>
    </>
  )
}

export default Brands
