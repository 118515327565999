import { ReactNode } from 'react'
import Footer from './Layout/Footer'
import Header from './Layout/Header'

const Layout = ({ children, searchQuery }: { children: ReactNode; searchQuery?: string }) => {
  return (
    <>
      <Header searchQuery={searchQuery} />
      <main className="bg-gray-50">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
