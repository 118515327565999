import { Currency, CurrencyCode, Money, MoneyPayload } from '@/types'
import isEqual from 'lodash/isEqual'
import CurrencyM from './Currency'

type FormatterOpts = Omit<Parameters<typeof Intl.NumberFormat>[1], 'style' | 'currency'>

// private

const newFormatter = (code: CurrencyCode, opts?: FormatterOpts) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: code, ...opts })

const init = (amount: number, currency: Currency): Money => ({ amount, currency })

// conversions

const fromPayload: {
  (payload: undefined): undefined
  (payload: null): null
  (payload: MoneyPayload): Money
} = (payload) => {
  if (!payload) return payload
  return init(Math.round(payload.amount), CurrencyM.fromCode(payload.currency))
}

const fromPayloadV2 = (json: string) => fromPayload(JSON.parse(json))

const fromInt = (number: number, currencyCode: CurrencyCode) =>
  init(number, CurrencyM.fromCode(currencyCode))

const fromDecimal = (number: number, currencyCode: CurrencyCode) =>
  fromInt(Math.round(number * 100), currencyCode)

const fromString = (string: string, currencyCode: CurrencyCode) =>
  fromDecimal(parseFloat(string), currencyCode)

const toPayload = (money: Money): MoneyPayload => ({
  amount: money.amount,
  currency: money.currency.code,
})

const toPayloadV2 = (money: Money) =>
  JSON.stringify({ amount: money.amount, currency: money.currency.code })

const toDecimal = (money: Money) => money.amount / 100

const toString = (money: Money) => toDecimal(money).toString()

const toInt = (money: Money) => money.amount

// math

const add = (money1: Money, money2: Money) => {
  if (!isEqual(money1.currency, money2.currency)) {
    throw new Error('Cannot add money in different currencies')
  }

  return init(money1.amount + money2.amount, money1.currency)
}

const sub = (money1: Money, money2: Money) => {
  if (!isEqual(money1.currency, money2.currency)) {
    throw new Error('Cannot subtract money in different currencies')
  }

  return init(money1.amount - money2.amount, money1.currency)
}

const mult = (money: Money, number: number) =>
  init(Math.round(money.amount * number), money.currency)

const ratio = (money1: Money, money2: Money) => {
  if (!isEqual(money1.currency, money2.currency)) {
    throw new Error('Cannot get the ratio of money in different currencies')
  }

  return money1.amount / money2.amount
}

const abs = (money: Money) => init(Math.abs(money.amount), money.currency)

// misc

const format = (money: Money, opts?: FormatterOpts) => {
  try {
    return newFormatter(money.currency.code, opts).format(toDecimal(money))
  } catch (err) {
    return newFormatter(money.currency as any, opts).format(toDecimal(money))
  }
}

const formatNoCents = (money: Money, opts?: FormatterOpts) =>
  format(money, { ...opts, maximumFractionDigits: 0 })

const isZero = (money: Money) => money.amount === 0

const isNeg = (money: Money) => money.amount < 0

const isPos = (money: Money) => money.amount > 0

const equals = (money1: Money, money2: Money) =>
  money1.amount === money2.amount && money1.currency.code === money2.currency.code

const compare = (money1: Money, money2: Money) => {
  if (!isEqual(money1.currency, money2.currency)) {
    throw new Error('Cannot compare money in different currencies')
  }

  if (money1.amount < money2.amount) return -1
  if (money1.amount > money2.amount) return 1
  return 0
}

const max = (money1: Money, money2: Money) => (compare(money1, money2) === 1 ? money1 : money2)

const avg = (monies: Money[]) => {
  const amount = Math.round(monies.reduce(add).amount / monies.length)
  return { amount, currency: monies[0].currency }
}

export default {
  fromPayload,
  fromPayloadV2,
  fromDecimal,
  fromString,
  fromInt,
  toPayload,
  toPayloadV2,
  toDecimal,
  toString,
  toInt,
  add,
  sub,
  mult,
  ratio,
  abs,
  format,
  formatNoCents,
  isZero,
  isNeg,
  isPos,
  equals,
  compare,
  max,
  avg,
}
