const PhoneIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.7071 10.707L8.70711 54.707"
      stroke="#111827"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2466 31.1508C24.7712 30.4382 24.3281 29.7016 23.9194 28.9432L28.1579 26.8211C29.1624 26.3192 29.9523 25.472 30.3826 24.4348C30.8129 23.3976 30.8548 22.24 30.5006 21.1745L30.5 21.1728L26.5055 9.192L26.5053 9.19142C26.1959 8.26279 25.6023 7.45496 24.8085 6.88223C24.0146 6.30938 23.0606 6.00074 22.0815 6H22.08H13.3333C11.3884 6 9.52315 6.77262 8.14788 8.14788C6.77262 9.52315 6 11.3884 6 13.3333V16C6 25.6599 9.26148 34.5583 14.7433 41.6541L17.5975 38.7998C12.8269 32.4488 10 24.5544 10 16V13.3333C10 12.4493 10.3512 11.6014 10.9763 10.9763C11.6014 10.3512 12.4493 10 13.3333 10H22.079C22.2187 10.0002 22.3548 10.0443 22.4681 10.126C22.5815 10.2079 22.6663 10.3233 22.7105 10.456L22.7107 10.4566L26.7048 22.4362C26.7554 22.5884 26.7494 22.7538 26.6879 22.902C26.6264 23.0501 26.5136 23.1712 26.3701 23.2429L26.3686 23.2436L20.35 26.257C19.3899 26.7376 18.9807 27.8905 19.4227 28.8688C20.2453 30.6893 21.2338 32.4152 22.3695 34.0278L25.2466 31.1508ZM20.1748 41.8794C27.113 49.3358 37.012 54 48 54H50.6667C51.5507 54 52.3986 53.6488 53.0237 53.0237C53.6488 52.3986 54 51.5507 54 50.6667V41.9227V41.9222C54 41.7822 53.956 41.6458 53.8742 41.5322C53.7923 41.4187 53.6768 41.3338 53.544 41.2895L53.5434 41.2893L41.5638 37.2952L41.5637 37.2952C41.4115 37.2446 41.2462 37.2506 41.098 37.3121C40.9499 37.3736 40.8288 37.4864 40.7571 37.6299L40.7564 37.6314L37.743 43.65C37.2624 44.6101 36.1095 45.0193 35.1312 44.5773C31.2198 42.81 27.7452 40.2768 24.892 37.1623L27.7235 34.3307C29.8118 36.6396 32.2885 38.5886 35.0568 40.0806L37.1789 35.8421C37.6808 34.8376 38.528 34.0477 39.5652 33.6174C40.6024 33.1871 41.76 33.1452 42.8255 33.4994L42.8273 33.5L54.808 37.4945L54.8086 37.4947C55.738 37.8044 56.5463 38.3987 57.1191 39.1934C57.692 39.9881 58.0001 40.943 58 41.9227V41.9231V50.6667C58 52.6116 57.2274 54.4769 55.8521 55.8521C54.4769 57.2274 52.6116 58 50.6667 58H48C35.9075 58 25.0082 52.889 17.3447 44.7095L20.1748 41.8794Z"
      fill="#111827"
    />
  </svg>
)

export default PhoneIcon
