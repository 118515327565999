import config from '@/config'
import { AppName } from '@/types'
import ConfigContext from '../contexts/ConfigContext'

const devScheme = config.devSsl === 'true' ? 'https' : 'http'
const devWsScheme = config.devSsl === 'true' ? 'wss' : 'ws'

const developersUrl = config.canonicalHost
  ? `https://developers.${config.canonicalHost}`
  : `${devScheme}://developers.local.gearflow.com:4000`

const suppliersUrl = config.canonicalHost
  ? `https://suppliers.${config.canonicalHost}`
  : `${devScheme}://suppliers.local.gearflow.com:4000`

const suppliersGqlWsUrl = config.canonicalHost
  ? `wss://suppliers.${config.canonicalHost}/socket/gql`
  : `${devWsScheme}://suppliers.local.gearflow.com:4000/socket/gql`

const buyersUrl = config.canonicalHost
  ? `https://dashboard.${config.canonicalHost}`
  : `${devScheme}://dashboard.local.gearflow.com:4000`

const buyersGqlWsUrl = config.canonicalHost
  ? `wss://dashboard.${config.canonicalHost}/socket/gql`
  : `${devWsScheme}://dashboard.local.gearflow.com:4000/socket/gql`

const adminUrl = config.canonicalHost
  ? `https://admin.${config.canonicalHost}`
  : `${devScheme}://admin.local.gearflow.com:4000`

const gfBaseUrl = config.canonicalHost
  ? `https://${config.canonicalHost}`
  : `${devScheme}://local.gearflow.com:4000`

const balanceDashboardBaseUrl =
  config.balanceEnv === 'prod'
    ? 'https://dashboard.getbalance.com'
    : 'https://dashboard.sandbox.getbalance.com'

const stripeDashboardBaseUrl =
  config.balanceEnv === 'prod'
    ? 'https://dashboard.stripe.com'
    : 'https://dashboard.stripe.com/test'

const buyersGqlTestingUrl = `${config.devsBaseUrl}/parts-hub-graphql-client`

const dealersUrl = config.canonicalHost
  ? `https://sales.${config.canonicalHost}`
  : `${devScheme}://sales.local.gearflow.com:4000`

const dealersGqlWsUrl = config.canonicalHost
  ? `wss://sales.${config.canonicalHost}/socket/gql`
  : `${devWsScheme}://sales.local.gearflow.com:4000/socket/gql`

const buyersMobileAppUrl = `gearflow-parts-hub://authenticated`

export const staticConfig = {
  developersUrl,
  suppliersUrl,
  suppliersGqlWsUrl,
  buyersGqlWsUrl,
  buyersUrl,
  adminUrl,
  gfBaseUrl,
  dealersUrl,
  dealersGqlWsUrl,
  balanceDashboardBaseUrl,
  stripeDashboardBaseUrl,
  buyersGqlTestingUrl,
  buyersMobileAppUrl,
  stripePublicKey: config.stripePublishableKey || '',
  rollbar: config.rollbar,
  convenienceFeeRate: 0.03,
  googleMapsApiKey: config.googleMapsApiKey || '',
}

const ConfigProvider = ({ app, children }: { app?: AppName; children: React.ReactNode }) => {
  const csrfToken = document
    .querySelector('meta[name=csrf-token]')
    ?.getAttribute('content') as string

  return (
    <ConfigContext.Provider value={{ ...staticConfig, csrfToken, app }}>
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
