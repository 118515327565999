import { App } from '@/retail/_gen/gql'
import logo from '@/retail/components/Layout/logo.png'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import A from '@/gf/components/A'

const FocusLayout = ({ app, children }: { app: App; children: ReactNode }) => (
  <div className="min-h-screen bg-gray-50 border-t-8 border-gearflow flex flex-col">
    <header className="px-8 py-6 flex justify-between items-start">
      <RouterLink to="/">
        <img src={logo} alt="Gearflow" className="w-36" />
      </RouterLink>
    </header>

    <div className="flex-1 pt-8 pb-4">
      <div className="max-w-md mx-auto space-y-6">
        <h1 className="text-2xl text-center">
          Welcome to {app === App.PartsHub ? 'Parts Hub' : app === App.SalesHub && 'Sales Hub'}!
        </h1>

        {children}

        <div className="text-gray-600 text-center">
          Need a hand?{' '}
          <A.T href="mailto:support@gearflow.com" className="text-gray-600">
            Message support
          </A.T>
        </div>
      </div>
    </div>
  </div>
)

export default FocusLayout
