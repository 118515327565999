import React from 'react'
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'

import UnexpectedError from './UnexpectedError'

// https://github.com/rollbar/rollbar-react/issues/69
const fallbackUI = UnexpectedError as unknown as React.ReactNode

const ErrorBoundary = ({ children }: { children?: React.ReactNode }) => (
  <RollbarErrorBoundary fallbackUI={fallbackUI}>{children}</RollbarErrorBoundary>
)

export default ErrorBoundary
