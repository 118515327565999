import { useQuery, gql } from '@apollo/client'

import { Category } from '../types'

interface Data {
  categories: Category[]
}

const query = gql`
  query FetchCategories {
    categories {
      id
      name
      slug
      slugPath
      imageUrl
      ascendants {
        id
        name
        slugPath
      }
      children {
        id
        name
        slug
        slugPath
        imageUrl
        ascendants {
          id
          name
          slugPath
        }
        children {
          id
          name
          slug
          slugPath
          imageUrl
          ascendants {
            id
            name
            slugPath
          }
          children {
            id
            name
            slug
            slugPath
            imageUrl
            ascendants {
              id
              name
              slugPath
            }
            children {
              id
              name
              slug
              slugPath
              imageUrl
              ascendants {
                id
                name
                slugPath
              }
              children {
                id
                name
                slug
                slugPath
                imageUrl
                ascendants {
                  id
                  name
                  slugPath
                }
                children {
                  id
                  name
                  slug
                  slugPath
                  imageUrl
                  ascendants {
                    id
                    name
                    slugPath
                  }
                  children {
                    id
                    name
                    slug
                    slugPath
                    imageUrl
                    ascendants {
                      id
                      name
                      slugPath
                    }
                    children {
                      id
                      name
                      slug
                      slugPath
                      imageUrl
                      ascendants {
                        id
                        name
                        slugPath
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const useFetchCategories = () => useQuery<Data>(query)

export default useFetchCategories
