import reports from './Reports/reports.png'
import reportsMobile from './Reports/reports_mobile.png'
import { motion } from 'framer-motion'
import TrendingIcon from './Reports/TrendingIcon'

const title = 'Insights that drive your improvement'

const Reports = () => (
  <motion.section className="grid grid-cols-1 sm:grid-cols-[auto_22rem] xl:grid-cols-[auto_33rem] items-center gap-y-8 gap-x-16">
    <figure className="sm:hidden order-2 relative w-full">
      <div className="relative w-[109vw] -left-[14vw]">
        <img src={reportsMobile} className="w-full h-full object-cover" alt={title} />
      </div>
    </figure>

    <motion.figure className="relative hidden sm:block h-[29rem] xl:h-[39.9375rem] mt-4 sm:mt-0">
      <motion.div className="hidden sm:block absolute inset-y-0 w-[45rem] xl:w-[58.375rem] -right-[2rem]">
        <motion.img
          src={reports}
          className="object-contain"
          alt={title}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-200px 1px' }}
          transition={{ duration: 0.5 }}
          variants={{
            visible: {
              opacity: 1,
              x: 0,
              transition: { type: 'spring', stiffness: 300, damping: 24 },
            },
            hidden: { opacity: 0, x: -200 },
          }}
        />
      </motion.div>
    </motion.figure>

    <div className="space-y-4 sm:space-y-8 w-full sm:w-[22rem] xl:w-[34.75rem] text-center sm:text-left order-1 sm:order-2">
      <div className="flex justify-center sm:justify-start">
        <TrendingIcon />
      </div>
      <h3 className="font-medium text-xl sm:text-3xl leading-6 sm:leading-9">{title}</h3>
      <p className="text-base sm:text-lg leading-6 sm:leading-7">
        No more operating in the dark: Easily see where you can save time, reduce costs, and improve
        performance.
      </p>
    </div>
  </motion.section>
)

export default Reports
