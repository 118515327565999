import useConfig from '@/gf/hooks/useConfig'
import classNames from 'classnames'

const SignOutLink = ({ className }) => {
  const config = useConfig()
  return (
    <form action="/logout" method="post">
      <input name="_method" type="hidden" value="delete" />
      <input name="_csrf_token" type="hidden" value={config.csrfToken} />
      <button className={classNames('text-left w-full', className)} type="submit">
        Sign out
      </button>
    </form>
  )
}

export default SignOutLink
