const Welcome = () => (
  <>
    <h2 className="mt-6 text-left text-4xl leading-9 font-extrabold text-gray-900">
      Welcome to Gearflow!
    </h2>
    <p className="block mt-4 text-left text-body leading-5 text-gray-600 max-w-full">
      Your customer&rsquo;s parts procurement partner.
    </p>
  </>
)

export default Welcome
