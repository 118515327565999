import React from 'react'
import { Link, useParams } from 'react-router-dom'

import usePage from '@/gf/hooks/usePage'
import useCategories from '../hooks/useCategories'
import useSearchSourceProducts from '../hooks/useSearchSourceProducts'
import CategoryTreeM from '../modules/CategoryTree'

import CategoryTree from '../components/CategoryTree'
import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import Pagination from '../components/Pagination'
import SourceProducts from '../components/SourceProducts'
import RightArrow from '../components/svgs/RightArrow'

import { type Category, AncestorCategory } from '../types'

const BreadCrumb = ({ category }: { category: AncestorCategory }) => (
  <>
    <Link to={`/c/${category.slugPath}`}>{category.name}</Link>
    <RightArrow />
  </>
)

const Category = () => {
  const slugPath = (useParams<{ ['*']: string }>() as { slugPath: string })['*']
  const [page, setPage] = usePage()
  const categories = useCategories()

  const search = useSearchSourceProducts({
    variables: {
      page,
      inSubtreeOfCategorySlugPath: slugPath,
    },
  }).data?.sourceProductsSearch

  const category = React.useMemo(
    () => categories && CategoryTreeM.findBySlugPath(categories, slugPath),
    [!categories, slugPath]
  )

  if (category === null) {
    return (
      <Layout>
        <section className="grid grid-cols-4 gap-4 px-4 sm:px-0 z-0 pb-4">
          <section className="col-span-4">
            <div className="max-w-3xl mx-auto">
              <p className="text-center my-8 text-xl">
                Sorry, the page you are looking for does not exist.
              </p>
            </div>
          </section>
        </section>
      </Layout>
    )
  }

  const tiledCategories = category ? category.children.filter((c) => c.imageUrl) : []

  return (
    <Layout>
      <Container className="py-4">
        {category && category.ascendants.length > 0 && (
          <div className="flex">
            {category.ascendants.map((c) => (
              <BreadCrumb category={c} key={c.id} />
            ))}
          </div>
        )}

        <h1 className="text-2xl leading-9 font-bold text-gray-700 tracking-tight sm:text-3xl px-4 sm:px-0 mb-8">
          {category?.name}
        </h1>

        <section className="md:grid grid-cols-4 gap-4 px-4 sm:px-0 z-0 pb-4">
          <section className="col-span-1 text-sm mb-8 hidden md:block">
            {category && <CategoryTree currentCategory={category} categories={categories} />}
          </section>
          <section className="col-span-3">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 grid-flow-row auto-rows-max mb-8">
              {tiledCategories.map((c) => (
                <Link
                  to={`/c/${c.slugPath}`}
                  className="p-4 bg-white grid justify-items-center content-center rounded-3xl border-2 border-gray-300"
                  key={c.id}
                >
                  <img src={c.imageUrl as string} alt={c.name} className="block w-32 mb-2" />
                  <div className="text-center leading-6">{c.name}</div>
                </Link>
              ))}
            </div>
            <SourceProducts sourceProducts={search?.sourceProducts} />
          </section>
        </section>

        <Pagination pagination={search?.pagination} page={page} updatePage={setPage} />
      </Container>
    </Layout>
  )
}

export default Category
