import { useQuery, gql } from '@apollo/client'

import { Product } from '../types'

interface Data {
  product: Product | null
}

interface Variables {
  slugPath: string
}

const query = gql`
  query FetchProduct($slugPath: String) {
    product(slugPath: $slugPath) {
      id
      name
      slugPath
      images {
        variants {
          name
          url
        }
      }
      availability
      mpn
      altMpn
      sku
      shortDescription
      description
      leadTime
      isOem
      condition
      category {
        id
      }
      brand {
        name
      }
      salePrice {
        amount
        currency
      }
      shippingCost {
        amount
        currency
      }
      store {
        id
        name
        city
        state
        shipsFree
        shippingInfo
        returnPolicy
        slug
      }
      fitsWithMachines {
        make
        model
        year
      }
    }
  }
`

const useProduct = (variables: Variables) => useQuery<Data, Variables>(query, { variables })

export default useProduct
