import { useLocation, Link } from 'react-router-dom'

import useConfig from '@/gf/hooks/useConfig'

import Section from './Section'
import OemBadge from './OemBadge'
import SourceProductInfo from './SourceProductInfo'
import SalePriceRange from './SalePriceRange'
import IssueBadge from './IssueBadge'

import { SourceProduct } from '../types'

const SourceProductAddToCart = ({ sourceProduct }: { sourceProduct?: SourceProduct }) => {
  const config = useConfig()
  const location = useLocation()

  return (
    <Section
      title={sourceProduct?.name}
      headerAction={sourceProduct?.isOem ? <OemBadge /> : undefined}
    >
      {sourceProduct && (
        <>
          <SourceProductInfo sourceProduct={sourceProduct} displayStores={false} />

          <h2 className="text-2xl my-6 font-bold">
            <SalePriceRange products={sourceProduct.products} />
          </h2>

          <div className="divide-gray-200 divide-y-2">
            {sourceProduct.products.map((product) => (
              <div className="py-4 grid gap-2" key={product.id}>
                <div>
                  <div className="flex items-center gap-4">
                    <div>{product.store.name}</div>
                    <div className="grow" />
                    {product.availability === 'backordered' && <IssueBadge>Backordered</IssueBadge>}
                  </div>
                  <div className="text-gray-700 text-sm">
                    {product.store.city}
                    {product.store.state && <>, {product.store.state}</>}
                  </div>
                </div>
                {product.availability === 'out_of_stock' ? (
                  <button
                    type="button"
                    className="grow px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gray-400 cursor-not-allowed"
                    disabled
                  >
                    Out of Stock
                  </button>
                ) : (
                  <form action="/cart" method="post" className="flex gap-4 items-center">
                    <input name="_csrf_token" type="hidden" value={config.csrfToken} />
                    <input id="product" name="product" type="hidden" value={product.id} />
                    <input
                      id="redirect_to"
                      name="redirect_to"
                      type="hidden"
                      value={location.pathname}
                    />

                    <Link to={`/itm/${product.slugPath}`} className="block leading-6 text-gearflow">
                      Product details
                    </Link>

                    <select className="border sort rounded-md" id="quantity" name="quantity">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                    <button
                      type="submit"
                      className="grow px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gearflow hover:bg-orange-700 focus:outline-none focus:ring transition duration-150 ease-in-out"
                    >
                      {}
                      Add to cart
                    </button>
                  </form>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </Section>
  )
}

export default SourceProductAddToCart
