import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../components/LayoutNext'
import PartsHub from './Pricing/PartsHub'
import SalesHub from './Pricing/SalesHub'

const Pricing = () => (
  <Layout>
    <Routes>
      <Route path="" element={<Navigate to="parts-hub" />} />
      <Route path="sales-hub" element={<SalesHub />} />
      <Route path="parts-hub" element={<PartsHub />} />
    </Routes>
  </Layout>
)

export default Pricing
