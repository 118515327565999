import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import Cog from './WeMakeItEasier/Cog'
import Dollar from './WeMakeItEasier/Dollar'
import LightBulb from './WeMakeItEasier/LightBulb'
import Tag from './WeMakeItEasier/Tag'

const Info = ({ icon: Icon, title, text }) => (
  <div className="flex flex-col sm:flex-row gap-4">
    <div className="flex-shrink-0">
      <Icon />
    </div>
    <div className="space-y-2 xl:space-y-4">
      <h3 className="font-medium text-lg xl:text-2xl leading-7 xl:leading-8">{title}</h3>
      <p className="text-sm xl:text-lg leading-6 xl:leading-7">{text}</p>
    </div>
  </div>
)

const WeMakeItEasier = () => (
  <LayoutContainer className="bg-orange-500 text-white">
    <section className="pt-16 pb-16 xl:pt-24 xl:pb-16 flex flex-col sm:flex-row gap-y-8 xl:gap-x-16">
      <div className="w-full sm:max-w-[31.5rem] space-y-8">
        <h2 className="text-center sm:text-left font-medium text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem]">
          We make it easier for your team and vendors
        </h2>
        <LinkButton href="/how-it-works" variation="secondary" className="hidden sm:inline-block">
          Learn More
        </LinkButton>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8 xl:gap-y-16">
        <Info
          icon={LightBulb}
          title="Fleet Managers"
          text="Lead with confidence: Use real-time insights to inform strategic decisions for your fleet."
        />
        <Info
          icon={Dollar}
          title="Parts Purchasing Managers"
          text="Stop putting out fires and start predicting your next move to fuel equipment uptime. "
        />
        <Info
          icon={Cog}
          title="Service Technicians"
          text="Say goodbye to parts headaches with the simplest way to get what you need when you need it."
        />
        <Info
          icon={Tag}
          title="Vendors"
          text="Wow your customers by bridging the gap between calls and ecommerce, while lowering your cost to serve."
        />
      </div>
      <div className="sm:hidden flex justify-center py-4">
        <LinkButton href="/how-it-works" variation="secondary">
          Learn More
        </LinkButton>
      </div>
    </section>
  </LayoutContainer>
)

export default WeMakeItEasier
