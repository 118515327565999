import { Duration } from 'luxon'
import pick from 'lodash/pick'
import pluralize from 'pluralize'

const human = (duration: Duration) => {
  const rescaledObj = duration.rescale().toObject()
  const twoHighestUnits = Object.keys(rescaledObj).slice(0, 2)
  const roundedObj = pick(rescaledObj, twoHighestUnits)

  return Duration.fromObject(roundedObj).toHuman({ unitDisplay: 'short' })
}

const avg = (durations: Duration[]) => {
  const data = durations.filter((d) => d !== null)
  if (data.length > 0) {
    return Duration.fromMillis(
      Math.round(data.map((d) => d.toMillis()).reduce((d1, d2) => d1 + d2) / data.length)
    )
  }

  return Duration.fromMillis(0)
}

const fromSeconds = (seconds: number) => Duration.fromMillis(seconds * 1000)

const format = (duration: Duration) => {
  if (duration.shiftTo('hours').hours < 1) {
    const minutes = duration.toFormat('m')
    return `${minutes} ${pluralize('minute', parseInt(minutes, 10))}`
  }

  if (duration.shiftTo('days').days < 1) {
    const hours = duration.toFormat('h')
    return `${hours} ${pluralize('hour', parseInt(hours, 10))}`
  }

  if (duration.shiftTo('weeks').weeks < 1) {
    const days = duration.toFormat('d')
    return `${days} ${pluralize('days', parseInt(days, 10))}`
  }

  if (duration.shiftTo('years').years < 1) {
    const weeks = Math.floor(duration.shiftTo('weeks').weeks)
    return `${weeks} ${pluralize('weeks', weeks)}`
  }

  const years = duration.toFormat('y')
  return `${years} ${pluralize('years', parseInt(years, 10))}`
}

const formatShort = (duration: Duration) => {
  if (duration.shiftTo('hours').hours < 1) return `${duration.toFormat('m')}m`
  if (duration.shiftTo('days').days < 1) return `${duration.toFormat('h')}h`
  if (duration.shiftTo('weeks').weeks < 1) return `${duration.toFormat('d')}d`
  if (duration.shiftTo('years').years < 1) return `${Math.floor(duration.shiftTo('weeks').weeks)}w`
  return `${duration.toFormat('y')}y`
}

export default { fromSeconds, human, avg, format, formatShort }
