import Link from '@/gf/components/Link'
import { ArrowLeftIcon } from '@heroicons/react/solid'

const Unclaimed = () => (
  <div className="space-y-4">
    <div className="text-xl">Claim Your Account</div>

    <div className="text-base">
      Good news! You already have an account, but haven&apos;t set a password. We&apos;ve sent you
      an email with instructions to set your password.
    </div>

    <div>
      <Link.T to=".." className="flex gap-2 items-center">
        <ArrowLeftIcon className="h-5 w-5" /> Back to sign up
      </Link.T>
    </div>
  </div>
)

export default Unclaimed
