import EmailAddress from '@/gf/modules/EmailAddress'

import { AcceptDealerInviteForm, AcceptDealerInviteFormErrors } from '../types'

const clean = (form: AcceptDealerInviteForm): AcceptDealerInviteForm => {
  const email = EmailAddress.parse(form.email)

  return { ...form, email }
}

const validate = (form: AcceptDealerInviteForm): AcceptDealerInviteFormErrors => {
  let errors = {}

  if (!EmailAddress.isValid(form.email)) {
    errors = { ...errors, email: "Doesn't look like an email" }
  }

  if (form.password.length < 8) {
    errors = { ...errors, password: 'Must be at least 8 characters' }
  }

  if (form.password !== form.passwordConfirmation) {
    errors = { ...errors, passwordConfirmation: "Doesn't match password" }
  }

  return errors
}

export default { clean, validate }
