import { useEffect, useRef, useState } from 'react'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import ProgressBar from './RequestSteps/ProgressBar'
import step1 from './RequestSteps/step_1.png'
import step2 from './RequestSteps/step_2.png'
import step3 from './RequestSteps/step_3.png'
import step4 from './RequestSteps/step_4.png'
import step5 from './RequestSteps/step_5.png'
import topBg from './RequestSteps/top_bg.svg'
import bottomBg from './RequestSteps/bottom_bg.svg'
import { motion, useScroll, AnimatePresence } from 'framer-motion'

const Section = ({ status, title, text, activeIndex, image }) => (
  <section className="flex flex-col justify-center sm:h-[70vh]">
    <div className="max-w-[29.125rem] flex-shrink-0 space-y-4 sm:space-y-6">
      <ProgressBar activeIndex={activeIndex} />
      <hgroup className="font-medium space-y-2">
        <h3 className="text-sm sm:text-lg">{status}</h3>
        <p className="text-2xl sm:text-3xl">{title}</p>
      </hgroup>
      <p className="text-base sm:text-lg">{text}</p>
    </div>
    <div className="w-screen sm:hidden flex-grow py-8 -mx-8">
      <img src={image} alt={title} />
    </div>
  </section>
)

const AnimatedImage = ({ visible, src }) => (
  <AnimatePresence>
    {visible && (
      <motion.img
        src={src}
        alt=""
        className="h-full w-full object-contain absolute"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      />
    )}
  </AnimatePresence>
)

const getImageFromScrollProgress = (value: number) =>
  value < 0.3 ? 1 : value < 0.45 ? 2 : value < 0.55 ? 3 : value < 0.75 ? 4 : 5

const RequestSteps = () => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [visibleImage, setVisibleImage] = useState(1)

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    layoutEffect: true,
    offset: ['start end', 'end start'],
  })

  useEffect(() => {
    const initialScroll = scrollYProgress.get()
    setVisibleImage(getImageFromScrollProgress(initialScroll))

    scrollYProgress.on('change', (scrollProgress) =>
      setVisibleImage(getImageFromScrollProgress(scrollProgress))
    )
  }, [])

  return (
    <>
      <LayoutContainer
        className="hidden sm:block pt-20 xl:pt-32 bg-no-repeat bg-orange-50"
        style={{
          backgroundImage: `url(${topBg})`,
          backgroundPosition: 'center -2vh',
          backgroundSize: '109vw',
        }}
      />

      <LayoutContainer
        className="bg-no-repeat overflow-visible bg-orange-50"
        style={{
          backgroundImage: `url(${bottomBg})`,
          backgroundPosition: 'center 101%',
          backgroundSize: '135vw',
        }}
      >
        <section>
          <h2 className="hidden sm:block text-3xl xl:text-5xl leading-[2rem] xl:leading-[3.25rem] font-medium text-center">
            How Parts Hub Pro Works
          </h2>
          <div className="flex py-8 xl:py-16" ref={sectionRef}>
            <div className="flex flex-col w-full sm:w-1/2">
              <Section
                status="Request Submitted"
                title="Easily create requests the moment parts are needed"
                text="Empower your technicians to send all the details your dealer needs in minutes. No more phone calls. No more missing information."
                activeIndex={0}
                image={step1}
              />

              <Section
                status="Request Quoted"
                title="Receive quotes from all your vendors in one place"
                text="Review price and availability from your local vendors, and instantly source alternative options when needed so your team is never waiting on parts."
                activeIndex={1}
                image={step2}
              />

              <Section
                status="PO Sent"
                title="Customize workflows for faster approvals"
                text="Eliminate the endless loop of back and forth to get internal sign-offs. Easily make the right purchasing decision while keeping your team informed in real time."
                activeIndex={2}
                image={step3}
              />

              <Section
                status="In Transit"
                title="Get automatically notified when parts are ready"
                text="Stop calling to check on order statuses and get instant alerts when parts are available via will call, shipping, or delivery."
                activeIndex={3}
                image={step4}
              />

              <Section
                status="Fulfilled"
                title="Mark orders received and report issues immediately"
                text="Easily verify that your order is ready for repairs & maintenance and let your vendor know if there are any problems."
                activeIndex={4}
                image={step5}
              />
            </div>
            <div className="hidden sm:flex flex-col w-1/2 h-auto relative">
              <div
                className="flex flex-col items-center w-full h-[70vh] sticky"
                style={{ top: 'calc(50% - 35vh)' }}
              >
                <AnimatedImage src={step1} visible={visibleImage === 1} />
                <AnimatedImage src={step2} visible={visibleImage === 2} />
                <AnimatedImage src={step3} visible={visibleImage === 3} />
                <AnimatedImage src={step4} visible={visibleImage === 4} />
                <AnimatedImage src={step5} visible={visibleImage === 5} />
              </div>
            </div>
          </div>
        </section>
      </LayoutContainer>
    </>
  )
}

export default RequestSteps
