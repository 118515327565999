import { gql, QueryHookOptions, useQuery } from '@apollo/client'

import { ProductsSearch } from '../types'

interface Data {
  productsSearch: ProductsSearch
}

interface Variables {
  page?: number
  storeId?: string
  sourceProductId?: string
  inSubtreeOfCategory?: string
  limit?: number
  order?: string
}

const query = gql`
  query SearchProducts(
    $page: Int
    $storeId: String
    $sourceProductId: String
    $inSubtreeOfCategory: String
    $limit: Int
    $order: String
  ) {
    productsSearch(
      page: $page
      storeId: $storeId
      sourceProductId: $sourceProductId
      inSubtreeOfCategory: $inSubtreeOfCategory
      limit: $limit
      order: $order
    ) {
      products {
        id
        name
        slugPath
        mpn
        altMpn
        shortDescription
        availability
        isOem
        salePrice {
          amount
          currency
        }
        shippingCost {
          amount
          currency
        }
        store {
          name
          slug
          shipsFree
          city
          state
        }
        images {
          variants {
            name
            url
          }
        }
      }
      pagination {
        totalPages
        totalResults
      }
    }
  }
`

const useProducts = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(query, options)

export default useProducts
