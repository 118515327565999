import { Link } from 'react-router-dom'

import useToggle from '@/gf/hooks/useToggle'

import ArrowIcon from './ArrowIcon'

const styles = { link: 'py-2 block' }

const WhoWeServeNav = () => {
  const [open, { toggle }] = useToggle(false)

  return (
    <>
      <button
        onClick={(_e) => toggle()}
        className="flex items-center w-full cursor-pointer text-left px-6 py-2"
        type="button"
      >
        <div className="grow">Who We Serve</div>
        <ArrowIcon open={open} />
      </button>

      {open && (
        <div className="bg-gray-100 py-2 px-6 text-base">
          <Link to="/solutions/buyer" className={styles.link}>
            Equipment Owners
          </Link>
          <Link to="/solutions/dealer" className={styles.link}>
            Dealers
          </Link>
          <Link to="/solutions/manufacturer" className={styles.link}>
            Manufacturers
          </Link>
          <Link to="/solutions/distributor" className={styles.link}>
            Distributors
          </Link>
        </div>
      )}
    </>
  )
}

export default WhoWeServeNav
