import useConfig from '@/gf/hooks/useConfig'

const useSignIn = () => {
  const config = useConfig()

  const signIn = async (form: { email: string; password: string }) => {
    const resp = await fetch('/sign_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': config.csrfToken,
      },
      body: JSON.stringify({ ...form, persistent_session: 'true' }),
    })

    if (resp.ok) {
      const returnPath = localStorage.getItem('return-path')
      localStorage.removeItem('return-path')
      window.location.href = returnPath || resp.url
    }

    return resp
  }

  return { signIn }
}

export default useSignIn
