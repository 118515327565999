import { forwardRef } from 'react'
import classNames from 'classnames'

type Props = Omit<JSX.IntrinsicElements['input'], 'value'> & {
  value?: string | null
  setValue?: (value: string) => void
}

const classes = {
  base: 'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder:text-gray-400 text-gray-900',
  disabled: 'cursor-not-allowed bg-gray-100 bg-opacity-50',
}

// Do not change. Wrap if needed.
const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ type = 'text', setValue, ...props }, ref) => {
    if (setValue && props.onChange) throw new Error('cannot provide both setValue and onChange')

    const onChange = setValue
      ? ({ target }: React.ChangeEvent<HTMLInputElement>) => setValue(target.value)
      : props.onChange

    const value = props.value !== undefined ? props.value || '' : undefined

    const className = classNames(
      classes.base,
      { [classes.disabled]: props.disabled },
      props.className
    )

    return (
      <input
        {...props}
        value={value}
        onChange={onChange}
        className={className}
        type={type}
        ref={ref}
      />
    )
  }
)

export default TextInput
