import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const HashLinks = () => {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // if a hash link, scroll to id
    if (hash !== '') {
      let retries = 0
      const id = hash.replace('#', '')
      const scroll = () => {
        retries += 1
        if (retries > 50) return
        const element = document.getElementById(id)
        if (element) {
          setTimeout(() => element.scrollIntoView(true), 0)
        } else {
          setTimeout(scroll, 100)
        }
      }
      scroll()
    }
  }, [pathname, hash, key])

  return null
}

export default HashLinks
