import LayoutContainer from '@/retail/components/LayoutNext/LayoutContainer'
import Dollar from './WhySalesHubPro/Dollar'
import Mobile from './WhySalesHubPro/Mobile'
import Clock from './WhySalesHubPro/Clock'
import { motion } from 'framer-motion'

const ListItem = ({ title, text, icon: Icon }) => (
  <motion.li
    className="flex flex-col sm:flex-row gap-4"
    variants={{
      visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'spring', stiffness: 300, damping: 24 },
        translateX: 0,
        skewX: 0,
      },
      hidden: {
        opacity: 0,
        scale: 0.7,
        transition: { duration: 0.4 },
        translateX: 200,
        skewX: 50,
      },
    }}
  >
    <span className="flex-shrink-0">
      <Icon />
    </span>

    <div className="space-y-4">
      <h3 className="text-2xl font-medium">{title}</h3>
      <p className="text-lg sm:max-w-[33.875rem]">{text}</p>
    </div>
  </motion.li>
)

const WhySalesHubPro = () => (
  <LayoutContainer
    className=" bg-orange-500 text-white"
    childrenClassName="px-8 sm:px-12 py-12 sm:pt-24 sm:pb-4 flex flex-col sm:flex-row gap-16 sm:gap-8 sm:items-center"
  >
    <h2 className="text-5xl font-medium w-full sm:w-1/2 text-center">Why Sales Hub Pro?</h2>

    <motion.ul
      className="w-full sm:w-1/2 space-y-12"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.4, delayChildren: 0.4, staggerChildren: 0.15 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      <ListItem
        icon={Dollar}
        title="Triple your conversion rate"
        text="Close more parts sales opportunities with automatic quoting, smart reminders, and insights that drive your growth."
      />

      <ListItem
        icon={Clock}
        title="Instantly quote all your orders"
        text="Never lose a sale to slow response time again — easily quote your customers whether they walk-in, call, or email."
      />

      <ListItem
        icon={Mobile}
        title="Handle more inbound business"
        text="Let customers buy from you wherever they are with 1-click mobile ordering that's easy to track and manage."
      />
    </motion.ul>
  </LayoutContainer>
)

export default WhySalesHubPro
