import { App } from '@/retail/_gen/gql'
import Layout from '@/retail/components/LayoutNext'
import LayoutContainer from '@/retail/components/LayoutNext/LayoutContainer'
import { ChartSquareBarIcon, ChatIcon, LightningBoltIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Link, Route, Routes } from 'react-router-dom'
import EmailStep from './EmailStep'
import Unclaimed from './Unclaimed'

const NavLink = ({ title, to, selected }) => (
  <li className="block">
    <Link
      to={to}
      className={classNames(
        'text-lg xl:text-2xl font-medium block pb-1.5 xl:pb-3 border-b-2 xl:border-b-[0.1875rem] ',
        selected ? 'border-orange-500 text-orange-500' : 'border-transparent text-gray-500'
      )}
    >
      {title}
    </Link>
  </li>
)

const Start = ({ app }: { app: App }) => (
  <Layout metaTitle="Construction Equipment Parts and Attachments | Gearflow">
    <LayoutContainer className="bg-gray-50 text-gray-800 py-16">
      <div className="flex flex-col gap-6 justify-center pb-12 sm:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-md">
          <h2 className="text-center text-4xl font-medium leading-[2.625rem]">
            Sign up for an account
          </h2>
          <p className="block text-center text-sm leading-5 text-gray-600 max-w-full mt-2">
            <a
              href="/login"
              className="text-center text-sm leading-5 max-w text-gearflow hover:text-orange-700 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Existing user? Sign in now.
            </a>
          </p>

          <ul className="flex justify-center gap-x-6 mt-8 pb-6">
            <NavLink to="/signup/parts-hub" selected={app === App.PartsHub} title="Parts Hub" />
            <NavLink to="/signup/sales-hub" selected={app === App.SalesHub} title="Sales Hub" />
          </ul>
        </div>

        <div className="flex flex-col gap-4 lg:flex-row justify-center items-center lg:items-start lg:space-x-8">
          <div className="flex flex-col space-y-2 lg:space-y-6 mx-auto w-full max-w-md text-lg">
            {app === App.SalesHub ? (
              <p>
                You&apos;re so close to getting a better way to manage your parts requests and
                orders! Create a free account in seconds that will make it easier to:{' '}
              </p>
            ) : (
              <p>
                It only takes seconds to get access to the competitive advantage for your parts
                process, including:
              </p>
            )}

            <div className="space-y-2 lg:space-y-4">
              <div className="flex gap-2 items-center">
                <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                  <LightningBoltIcon className="h-6 w-6" />
                </div>
                {app === App.SalesHub ? (
                  <p className="text-lg leading-6">Instantly prioritize inbound parts requests</p>
                ) : (
                  <p className="text-lg leading-6">
                    One place to manage your parts sourcing, from requests to delivery
                  </p>
                )}
              </div>

              <div className="flex gap-2 items-center">
                <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                  <ChatIcon className="h-6 w-6" />
                </div>
                {app === App.SalesHub ? (
                  <p className="text-lg leading-6">Centralize messaging with your customers</p>
                ) : (
                  <p className="text-lg leading-6">
                    Instant messaging on all requests and orders to cut calls in half
                  </p>
                )}
              </div>

              <div className="flex gap-2 items-center">
                <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                  <ChartSquareBarIcon className="h-6 w-6" />
                </div>

                {app === App.SalesHub ? (
                  <p className="text-lg leading-6">
                    Quickly submit and track all orders in real-time
                  </p>
                ) : (
                  <p className="text-lg leading-6">
                    Real-time insights that fuel equipment uptime and reduce costs (available with
                    Pro + Enterprise plans)
                  </p>
                )}
              </div>
            </div>

            <p>Simply fill out the form, and you&apos;re in!</p>
          </div>

          <div className="flex flex-col items-stretch justify-center mx-auto w-full max-w-md sm:py-8 sm:px-10 py-4 px-4 bg-white shadow rounded-md sm:rounded-lg">
            <Routes>
              <Route path="" element={<EmailStep />} />
              <Route path="unclaimed" element={<Unclaimed />} />
            </Routes>
          </div>
        </div>
      </div>
    </LayoutContainer>
  </Layout>
)

export default Start
