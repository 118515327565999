import React from 'react'

type Props = Pick<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
  'htmlFor'
> & {
  children: React.ReactNode
  className?: string
  disabled?: boolean
}

const Label = ({ className, disabled = false, children, ...props }: Props) => {
  const classNames = className || `block font-medium text-sm ${disabled ? 'opacity-50' : ''}`
  return (
    <label {...props} className={classNames}>
      {children}
    </label>
  )
}

export default Label
