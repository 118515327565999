import Card from './Card'

const UnexpectedError = () => (
  <div className="p-8 bg-gray-50 h-screen">
    <div className="flex justify-center">
      <Card title="Whoops, something went wrong." className="max-w-sm">
        <Card.Section>For help, contact support@gearflow.com</Card.Section>
      </Card>
    </div>
  </div>
)

export default UnexpectedError
