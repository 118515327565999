import React from 'react'
import chunk from 'lodash/chunk'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ChevronRightIcon, CogIcon } from '@heroicons/react/outline'

import useCategories from '../../hooks/useCategories'

import { Category } from '../../types'

const styles = {
  subCategoryLink: 'block py-2 px-6 whitespace-nowrap hover:bg-gray-200',
}

const ShopNow = () => {
  const categories = useCategories()
  const [selectedCategory, setSelectedCategory] = React.useState<Category>()

  const subCategoryLinksChunked =
    selectedCategory &&
    chunk(
      [
        <Link to={`/c/${selectedCategory.slugPath}`} className={styles.subCategoryLink}>
          All {selectedCategory.name}
        </Link>,
        ...selectedCategory.children.map((category) => (
          <Link to={`/c/${category.slugPath}`} className={styles.subCategoryLink} key={category.id}>
            {category.name}
          </Link>
        )),
      ],
      9
    )

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group inline-flex items-center rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 whitespace-nowrap'
            )}
          >
            <span>Shop Now</span>
            <ChevronDownIcon
              className={classNames(
                open ? 'text-gray-600' : 'text-gray-400',
                'ml-2 h-5 w-5 group-hover:text-gray-500'
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-0 mt-3 px-2">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative bg-white flex">
                  <div className="py-4">
                    {categories?.map((category) => (
                      <button
                        onClick={() => setSelectedCategory(category)}
                        className={`py-2 px-6 flex w-full gap-4 items-center justify-between hover:bg-gray-50 ${
                          selectedCategory?.id === category.id ? 'bg-gray-100' : ''
                        }`}
                        type="button"
                        key={category.id}
                      >
                        {category.name}
                        <ChevronRightIcon className="w-4 h-4" />
                      </button>
                    ))}
                  </div>
                  {subCategoryLinksChunked?.map((subCategoryLinks) => (
                    <div className="py-4 bg-gray-100">{subCategoryLinks}</div>
                  ))}
                  {!subCategoryLinksChunked && (
                    <div className="py-4 bg-gray-100">
                      <CogIcon className="text-gray-200 w-60 h-60" />
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default ShopNow
