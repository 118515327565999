import bgTop from './ProductIntro/bg_top.svg'
import bgBottom from './ProductIntro/bg_bottom.svg'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import Vendors from './ProductIntro/Vendors'
import Requests from './ProductIntro/Requests'
import Reports from './ProductIntro/Reports'

const ProductIntro = () => (
  <>
    <LayoutContainer
      className="pt-16 sm:pt-32 bg-orange-50 product-intro-top-bg"
      style={{
        backgroundImage: `url(${bgTop})`,
      }}
    />

    <LayoutContainer
      className="pb-16 bg-no-repeat bg-orange-50"
      style={{
        backgroundImage: `url(${bgBottom})`,
        backgroundPosition: 'center 100.1%',
        backgroundSize: '101vw',
      }}
      childrenClassName="space-y-12 sm:space-y-16"
    >
      <h2 className="text-3xl leading-9 xl:text-5xl xl:leading-[3.25rem] font-medium text-center max-w-[61rem] mx-auto">
        The mixed-fleet parts solution that works for you, your team, and your vendors
      </h2>

      <Vendors />
      <Requests />
      <Reports />
    </LayoutContainer>
  </>
)

export default ProductIntro
