const ArrowIcon = ({ open }: { open: boolean }) => (
  <svg
    className={`h-5 w-5 transform text-gray-500 ${open ? '-rotate-0' : '-rotate-90'}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
  </svg>
)

export default ArrowIcon
