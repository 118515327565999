import { motion } from 'framer-motion'
import base from './base.png'
import fieldTech from './field_tech.png'
import vendor from './vendor.png'
import fleetManager from './fleet_manager.png'
import purchasingManager from './purchasing_manager.png'

const DesktopImage = () => (
  <motion.div
    variants={{
      hidden: {
        opacity: 0.8,
      },
      visible: {
        opacity: 1,
      },
    }}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    className="hidden sm:block relative w-[17rem] xl:w-[25rem] mx-auto"
  >
    <motion.img
      src={base}
      alt=""
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
        },
      }}
    />
    <motion.img
      src={fieldTech}
      alt=""
      variants={{
        hidden: { opacity: 0, translateX: 100 },
        visible: {
          transition: {
            delay: 0.1,
          },
          opacity: 1,
          translateX: 0,
        },
      }}
      className="absolute -right-[7rem] top-[2rem] xl:top-[3rem] w-[11rem] xl:w-[15rem] object-contain"
    />
    <motion.img
      src={vendor}
      alt=""
      variants={{
        hidden: { opacity: 0, translateX: 100 },
        visible: {
          transition: {
            delay: 0.2,
          },
          opacity: 1,
          translateX: 0,
        },
      }}
      className="absolute -right-[8rem] top-[7.5rem] xl:top-[11.5rem] w-[12rem] xl:w-[16rem] object-contain"
    />
    <motion.img
      src={purchasingManager}
      alt=""
      variants={{
        hidden: { opacity: 0, translateX: 100 },
        visible: {
          transition: {
            delay: 0.4,
          },
          opacity: 1,
          translateX: 0,
        },
      }}
      className="absolute -right-[8rem] top-[15.5rem] xl:top-[22.5rem] w-[12rem] xl:w-[16rem] object-contain"
    />
    <motion.img
      src={fleetManager}
      alt=""
      variants={{
        hidden: { opacity: 0, translateX: -100 },
        visible: {
          transition: {
            delay: 0.6,
          },
          opacity: 1,
          translateX: 0,
        },
      }}
      className="absolute -left-[7.5rem] xl:-left-[10rem] top-[10.5rem] xl:top-[15.5rem] w-[10rem] xl:w-[14rem] object-contain"
    />
  </motion.div>
)

export default DesktopImage
