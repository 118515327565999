import { ProductImage } from '../../types'

const defaultVariants = [
  {
    name: 'main',
    url: '/svg/gf/product-image.svg',
  },
  {
    name: 'card',
    url: '/svg/gf/product-image-placeholder.svg',
  },
]

const url = (image: ProductImage | undefined, variantName: string) => {
  const variants = image ? image.variants : defaultVariants
  const variant = variants.filter((v) => v.name === variantName)[0]
  return variant.url
}

export default { defaultVariants, url }
