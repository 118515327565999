import React from 'react'
import { UseToggleActions } from '../../types'

const useToggle = (initialValue = false) => {
  const [value, setValue] = React.useState<boolean>(initialValue)

  const actions: UseToggleActions = React.useMemo(() => {
    const toggle = () => setValue((v) => !v)
    const on = () => setValue(true)
    const off = () => setValue(false)

    return { toggle, on, off }
  }, [])

  return [value, actions] as const
}
export default useToggle
