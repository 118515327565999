import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import GoogleButton from '@/gf/components/GoogleButton'
import TextInput from '@/gf/components/inputs/Text'
import MicrosoftButton from '@/gf/components/MicrosoftButton'
import Spinner from '@/gf/components/Spinner'
import { cn } from '@/gf/modules/utils'
import useSession from '@/retail/hooks/useSession'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { HTMLAttributes, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import useContinueWithGoogle from '../GoogleAuth/useContinue'
import OrDivider from '../Microsoft/OrDivider'
import useContinueWithMicrosoft from '../Microsoft/useContinue'
import useSignIn from './useSignIn'

const Box = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'bg-white py-4 px-4 sm:py-8 sm:px-10 shadow rounded-md sm:rounded-lg space-y-4',
      className
    )}
    {...props}
  />
)

export const popReturnPath = () => {
  const key = 'return-path'
  const returnPath = localStorage.getItem(key)
  localStorage.removeItem(key)
  return returnPath
}

const SignInForm = () => {
  const [query, setQuery] = useQueryParams({
    return_path: StringParam,
    error: StringParam,
    signup: StringParam,
    email: StringParam,
    note: StringParam,
  })

  const session = useSession()
  const navigate = useNavigate()
  const [form, setForm] = useState<{ email: string; password: string }>({ email: '', password: '' })
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(query.error)
  const { signIn } = useSignIn()
  const [goTo, setGoTo] = useState<string>()

  const args = {
    onSignedIn: (returnPath: string) => setGoTo(popReturnPath() || returnPath),
    onNoMatch: () => setGoTo('/signup'),
  }

  const microsoft = useContinueWithMicrosoft(args)
  const google = useContinueWithGoogle(args)

  useEffect(() => {
    if (goTo)
      if (goTo.startsWith('http')) window.location.href = goTo
      else navigate(goTo)
  }, [goTo])

  useEffect(() => {
    if (query.return_path) localStorage.setItem('return-path', query.return_path)
    if (query.email) setForm({ ...form, email: query.email })
    if (query.note) setError(query.note)

    setQuery({
      error: undefined,
      return_path: undefined,
      signup: undefined,
      email: undefined,
      note: undefined,
    })
  }, [])

  const errorMsg =
    error &&
    {
      invalid_credentials: 'Incorrect email or password.',
      inactive_user: 'Inactive user account.',
      invalid_token: 'Invalid link token',
      'email-taken': 'You already have an account.',
    }[error]

  const onSubmit = async () => {
    setError(undefined)
    setSubmitting(true)

    const resp = await signIn(form)

    if (!resp.ok) {
      const data = await resp.json()
      setError(data.error)
    }

    setSubmitting(false)
  }

  if (microsoft.loading || google.loading)
    return (
      <Box className="text-center space-y-4">
        <Spinner />
      </Box>
    )

  return (
    <Form onSubmit={onSubmit}>
      <Box className="space-y-6">
        {errorMsg && (
          <div className="text-red-600 flex gap-1 items-center">
            <ExclamationCircleIcon className="h-5 w-5" />
            {errorMsg}
          </div>
        )}

        <div className="space-y-2">
          <MicrosoftButton className="w-full" href={microsoft.authUrl} />

          {session?.featureFlags.googleSignIn && (
            <GoogleButton className="w-full" href={google.authUrl} />
          )}
        </div>

        <OrDivider />

        <div className="space-y-4">
          <Field label="Email" htmlFor="user_email">
            <TextInput
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              id="user_email"
              type="email"
              placeholder="Your email address"
            />
          </Field>

          <Field label="Password" htmlFor="user_password">
            <TextInput
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              id="user_password"
              type="password"
              placeholder="Your password"
            />
          </Field>

          <div className="space-y-6">
            <div className="flex justify-between items-center gap-8">
              <Action.P className="grow" disabled={submitting} type="submit">
                {submitting ? <>Signing in&hellip;</> : 'Sign in'}
              </Action.P>

              <A.T className="text-gearflow text-sm" href="/reset-password">
                Forgot password?
              </A.T>
            </div>
          </div>

          <p className="pt-2 text-sm leading-5 max-w-full text-gray-600">
            Don&apos;t have an account yet?{' '}
            <A.T href="/signup" className="text-gearflow">
              Sign up now.
            </A.T>
          </p>
        </div>
      </Box>
    </Form>
  )
}

export default SignInForm
