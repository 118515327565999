import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import Welcome from './DealerInvite/Welcome'
import About from './DealerInvite/About'
import AcceptForm from './DealerInvite/AcceptForm'

const DealerInvite = () => (
  <Layout>
    <Container className="py-4">
      <div className="rounded-none bg-background sm:rounded-lg">
        <div className="m-10">
          <div className="relative overflow-hidden">
            <svg
              className="absolute text-background-light w-103 h-103 md:w-165 md:h-165 -left-36 md:-left-52 -top-44 md:-top-40"
              aria-hidden="true"
            >
              <use xlinkHref="/svg/gf/sprite.svg#hexagon" />
            </svg>
            <div className="hidden md:block pattern-grid-light w-75 h-60 absolute bottom-72 left-1/2 -m-8" />
            <div className="md:w-full md:grid md:grid-cols-6 md:items-start gap-4 my-6 relative">
              <div className="md:hidden">
                <Welcome />
              </div>
              <div className="hidden md:block md:col-span-4">
                <Welcome />
                <About />
              </div>
              <div className="md:col-span-2 md:col-start-5 bg-transparent flex flex-col justify-center pb-8">
                <AcceptForm />
              </div>
              <div className="md:hidden">
                <About />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default DealerInvite
