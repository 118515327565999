import LukePhoto from './LukePhoto.png'
import BenPhoto from './BenPhoto.png'
import SarahPhoto from './SarahPhoto.png'
import KarenPhoto from './KarenPhoto.png'
import ToranPhoto from './ToranPhoto.png'
import PeterPhoto from './PeterPhoto.png'

const people = [
  {
    name: 'Luke Powers',
    role: 'Founder & CEO',
    imageUrl: LukePhoto,
  },
  {
    name: 'Ben Preston',
    role: 'Co-Founder & COO',
    imageUrl: BenPhoto,
  },
  {
    name: 'Sarah Wilcox',
    role: 'VP of Business Development',
    imageUrl: SarahPhoto,
  },
  {
    name: 'Toran Billups',
    role: 'VP of Engineering',
    imageUrl: ToranPhoto,
  },
  {
    name: 'Karen Scally',
    role: 'Director of Marketing',
    imageUrl: KarenPhoto,
  },
  {
    name: 'Peter Raisch',
    role: 'Director of Sales',
    imageUrl: PeterPhoto,
  },
]

export default function AboutMiddle() {
  return (
    <div className="bg-white pt-1 pb-10 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet our leadership
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our team brings a wealth of experience in both the construction equipment industry and
            tech to help make the parts ordering process easier.
          </p>
        </div>
        <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-orange-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
