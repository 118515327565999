const toQueryString = (obj: Record<string, string>) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

const authParams = {
  client_id: '404cbdc5-9391-4910-ba49-fe350f4a8168',
  response_type: 'code',
  response_mode: 'query',
}

export const microsoftAuthUrl = ({
  redirectUri,
  scopes = [],
  state,
}: {
  redirectUri: string
  scopes?: string[]
  state?: string
}) =>
  `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${toQueryString({
    ...authParams,
    scope: ['User.Read', 'offline_access', ...scopes].join(' '),
    redirect_uri: redirectUri,
    ...(state !== undefined ? { state } : {}),
  })}`
