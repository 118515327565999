const LightBulb = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.326 34H28.672M24 6V8M36.728 11.272L35.314 12.686M42 24H40M8 24H6M12.686 12.686L11.272 11.272M16.928 31.072C15.5297 29.6734 14.5776 27.8915 14.192 25.9518C13.8063 24.0121 14.0045 22.0015 14.7615 20.1744C15.5184 18.3473 16.8001 16.7857 18.4446 15.687C20.089 14.5883 22.0223 14.0019 24 14.0019C25.9777 14.0019 27.911 14.5883 29.5554 15.687C31.1999 16.7857 32.4816 18.3473 33.2385 20.1744C33.9955 22.0015 34.1937 24.0121 33.808 25.9518C33.4224 27.8915 32.4703 29.6734 31.072 31.072L29.976 32.166C29.3494 32.7927 28.8524 33.5367 28.5134 34.3555C28.1743 35.1742 27.9999 36.0518 28 36.938V38C28 39.0609 27.5786 40.0783 26.8284 40.8284C26.0783 41.5786 25.0609 42 24 42C22.9391 42 21.9217 41.5786 21.1716 40.8284C20.4214 40.0783 20 39.0609 20 38V36.938C20 35.148 19.288 33.43 18.024 32.166L16.928 31.072Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LightBulb
