import Layout from '../components/LayoutNext'
import Customers from '../components/Customers'
import IntroHero from './PartsHub/IntroHero'
import RequestSteps from './PartsHub/RequestSteps'
import BranchCivilTestimonial from './PartsHub/BranchCivilTestimonial'

import ContactCallout from '../components/ContactCallout'
import SiteUrls from '../modules/SiteUrls'
import FAQ from '../components/FAQ'
import { PartsHubPricing } from '../components/PricingSection'

const PartsHub = () => (
  <Layout>
    <IntroHero />
    <Customers className="-mt-[4.5rem]" />
    <RequestSteps />
    <BranchCivilTestimonial />
    <PartsHubPricing />
    <FAQ
      questions={[
        {
          question: 'How do we get started?',
          answer: [
            'Connect with us, and we’ll get you on your way to adopting a better parts process!',
            'We know change can be hard, so we follow a three-step plan for a smooth roll-out that includes aligning on your objectives, defining a job or segment of your equipment division to start your implementation, and scheduling an on-site activation to set you up for success.',
          ],
        },

        {
          question: 'Do my vendors need a Gearflow account?',
          answer: [
            'No account is necessary — your vendors will receive your parts requests via email or text.',
            'It’s completely free for them to quote you price and availability through Parts Hub Pro, and they’ll continue to invoice you as usual.',
          ],
        },

        {
          question: 'What support do you offer?',
          answer: [
            'We have an expert team available to assist you from 8 a.m. to 5 p.m. CT Monday through Friday, except holidays.',
            'You can reach them on every request and order via Gearflow Messages or support@gearflow.com.',
          ],
        },
      ]}
    />
    <ContactCallout
      scheduleDemoUrl={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
      text="Ready to explore the “final frontier” of fleet management? We'll guide you every step of the way."
    />
  </Layout>
)

export default PartsHub
