// Deprecated: use Action

import { ButtonHTMLAttributes, forwardRef } from 'react'
import Action from './Action'

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'ref'> & {
  primary?: boolean
  performing?: boolean
}

const ButtonOld = forwardRef<HTMLButtonElement, Props>(
  ({ primary = true, children, ...props }, ref) => {
    const Comp = primary ? Action.P : Action.S

    return (
      <Comp {...props} ref={ref}>
        {children || props.title}
      </Comp>
    )
  }
)

export default ButtonOld
