import AboutPageTop from './AboutPageTop.png'

const stats = [
  { label: 'Founded', value: '2018' },
  { label: 'Employees', value: '15' },
  { label: 'Users', value: '4k+' },
  { label: 'Raised', value: '$10.1M' },
]

export default function AboutTop() {
  return (
    <div className="relative bg-white pb-1 pt-16 sm:py-24">
      <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
        <div className="relative sm:py-16 lg:py-0">
          <div
            aria-hidden="true"
            className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:px-0 lg:py-20">
            <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={AboutPageTop}
                alt=""
              />
              <div className="absolute inset-0 bg-orange-to-t from-indigo-600 via-indigo-600 opacity-90" />
              <div className="relative px-8" />
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:px-0">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              About Gearflow
            </h2>
            <div className="mt-6 space-y-6 text-gray-500">
              <p className="text-lg">
                We are on a mission to simplify the parts ordering process for heavy equipment
                fleets by providing better parts supplier access, communication, and reporting, in
                one easy-to-use platform.
              </p>
            </div>
          </div>
          <div className="mt-10">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
              {stats.map((stat) => (
                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                  <dd className="text-3xl font-bold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div>
        <div className="max-w-3xl max-h-lg mx-auto">
          <div className="mx-4 grid place-items-center mt-40 mb-20 aspect-w-16 aspect-h-9">
            <iframe
              frameBorder="0"
              src="https://www.youtube.com/embed/cgQTb9r74g4"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
