import sarahAndSam from './ContactCallout/sarah_and_sam.png'
import LayoutContainer from './LayoutNext/LayoutContainer'
import LinkButton from './LayoutNext/LinkButton'

const ContactCallout = ({ scheduleDemoUrl, text }) => (
  <LayoutContainer className="bg-gray-900">
    <section className="flex flex-col sm:flex-row-reverse sm:items-center">
      <div className="py-8 sm:p-8 xl:p-16 text-white">
        <hgroup className="space-y-2">
          <h2 className="text-sm sm:text-base">Still have questions?</h2>
          <p className="text-3xl font-medium">We&apos;re here to help</p>
        </hgroup>
        <p className="text-base mt-6">{text}</p>
        <div className="flex gap-x-4 mt-8">
          <LinkButton href={scheduleDemoUrl} target="_blank">
            Book a Discovery Call
          </LinkButton>
          <LinkButton href="/contact-us" variation="secondary">
            Contact Us
          </LinkButton>
        </div>
      </div>

      <div className="-mx-8 sm:mx-0 self-stretch">
        <img
          src={sarahAndSam}
          alt="Gearflow team working"
          className="w-[42.31rem] h-full object-cover"
        />
      </div>
    </section>
  </LayoutContainer>
)

export default ContactCallout
