const WistiaVideo = ({ videoId, title }) => {
  return (
    <>
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
        >
          <iframe
            src={`https://fast.wistia.net/embed/iframe/${videoId}?seo=false&videoFoam=true`}
            title={title}
            allow="autoplay; fullscreen"
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
    </>
  )
}

export default WistiaVideo
