import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/outline'

import useConfig from '@/gf/hooks/useConfig'
import useSession from '../../hooks/useSession'

const styles = {
  navItem: `inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 whitespace-nowrap`,
}

const User = () => {
  const config = useConfig()
  const session = useSession()

  if (!session) return null

  const { user } = session

  if (!user) return null

  return (
    <Popover className="relative">
      {(_props: { open: boolean }) => (
        <>
          <Popover.Button className={`${styles.navItem} hidden md:flex items-center gap-1`}>
            <UserCircleIcon className="h-6 w-6" />
          </Popover.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 right-0 transform translate-x-8 mt-3 px-2 w-screen max-w-xs">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative bg-white py-6">
                  <div className="px-8 py-2 font-bold">{user.name}</div>
                  <a href={`${config.buyersUrl}/`} className="block px-8 py-2 hover:bg-gray-100">
                    Parts Hub
                  </a>
                  <a
                    href={`${config.buyersUrl}/rfqs`}
                    className="block pl-10 pr-8 py-2 hover:bg-gray-100"
                  >
                    Requests
                  </a>
                  <a
                    href={`${config.buyersUrl}/orders`}
                    className="block pl-10 pr-8 py-2 hover:bg-gray-100"
                  >
                    Orders
                  </a>
                  <a
                    href={`${config.buyersUrl}/reporting`}
                    className="block pl-10 pr-8 py-2 hover:bg-gray-100"
                  >
                    Reports
                  </a>
                  <form action="/logout" method="post">
                    <input name="_method" type="hidden" value="delete" />
                    <input name="_csrf_token" type="hidden" value={config.csrfToken} />
                    <button
                      className="block px-8 py-2 hover:bg-gray-100 w-full text-left"
                      type="submit"
                    >
                      Sign out
                    </button>
                  </form>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default User
