import Modal from './Modal'

interface Props {
  open: boolean
  onClose: () => void
  shippingInfo: string
}
const ShippingInfoModal = ({ open, onClose, shippingInfo }: Props) => (
  <Modal title="Shipping Information" open={open} onClose={onClose} cancelText="Close">
    <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: shippingInfo }} />
  </Modal>
)

export default ShippingInfoModal
