import { SignUpError, SsoLinkType } from '@/retail/_gen/gql'
import { useState } from 'react'

type Fields = {
  sso: {
    linkId: string
    linkType: SsoLinkType
    email: string
  } | null
  email: string
  password: string
  passwordConfirmation: string
  name: string
  phoneNumber: string
  companyName: string
  emailOptIn: boolean
  code: string
}

type Errors = Partial<Omit<SignUpError, '__typename'>>

const key = 'sign-up-form-v2'

const initState = {
  fields: {
    sso: null,
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    phoneNumber: '',
    companyName: '',
    emailOptIn: true,
    code: '',
  },
  errors: null,
}

const load = () => {
  const item = localStorage.getItem(key)
  if (!item) return null
  return JSON.parse(item) as { fields: Fields; errors: Errors }
}

const save = (fields: Fields, errors: Errors | null) =>
  localStorage.setItem(key, JSON.stringify({ fields, errors }))

const clear = () => localStorage.removeItem(key)

const useSignUp = () => {
  const init = load() || initState
  const [fields, setFields] = useState(init?.fields || init.fields)
  const [errors, setErrorsState] = useState<Errors | null>(init?.errors || null)

  const setErrors = (newErrors: Errors | null) => {
    save(fields, newErrors)
    setErrorsState(newErrors)
  }

  const updateFields = (updates: Partial<Fields>) => {
    const code =
      updates.code ||
      (updates.passwordConfirmation && fields.code === ''
        ? `${parseInt('0xFFC1074', 16)}`
        : fields.code)

    setFields((prev) => {
      const newFields = { ...prev, ...updates, code }
      save(newFields, errors)
      return newFields
    })
  }

  const reset = () => {
    clear()
    setFields(initState.fields)
    setErrorsState(initState.errors)
  }

  return { fields, errors, updateFields, setErrors, reset }
}

export default useSignUp
