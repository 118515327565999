import Spinner from '@/gf/components/Spinner'
import { SsoLinkType, useRedeemOAuthCodeMutation } from '@/retail/_gen/gql'
import useGqlClient from '@/retail/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

const appendToQueryString = (queryString: string, name: string, value: string) => {
  const params = new URLSearchParams(queryString)
  params.append(name, value)
  return params.toString()
}

const BaseOAuth = ({ linkType }: { linkType: SsoLinkType }) => {
  const navigate = useNavigate()
  const [query, setQuery] = useQueryParams({ code: StringParam, state: StringParam })
  const [redeemCode] = useRedeemOAuthCodeMutation({ client: useGqlClient() })
  const [code, setCode] = useState<string>()

  useEffect(() => {
    if (query.code) {
      setCode(query.code)
      setQuery({ code: undefined })
    } else {
      navigate('/', { replace: true })
    }
  }, [])

  const process = async () => {
    if (!code || !query.state) return

    const linkId = (await redeemCode({ variables: { code, linkType } })).data?.redeemOauthCode

    if (!linkId) return

    const [path, qryStr] = query.state.split('?')
    let queryString = appendToQueryString(qryStr, 'link-type', linkType)
    queryString = appendToQueryString(queryString, 'link-id', linkId)
    navigate(`${path}?${queryString}`, { replace: true })
  }

  useEffect(() => {
    process()
  }, [code])

  return (
    <div className="p-8 flex items-center">
      <Spinner />
    </div>
  )
}

export default BaseOAuth
