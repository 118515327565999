import { Disclosure } from '@headlessui/react'
import {
  ChevronDownIcon,
  CloudUploadIcon,
  DeviceMobileIcon,
  InboxInIcon,
  ReplyIcon,
  SearchIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'

import A from '@/gf/components/A'
import header from './DistributorSolutions/header.png'
import quote from './DistributorSolutions/quote.jpeg'
import screenshot from './DistributorSolutions/sales-hub-dashboard-screenshot.png'
import support from './support.jpg'

import Layout from '../components/Layout'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const features = [
  {
    name: 'Unlimited listings',
    description:
      'Easily upload and update your entire catalog at list price across your distributor network.',
    icon: CloudUploadIcon,
  },
  {
    name: 'Inbound RFQs',
    description:
      'Automatically receive parts requests from prospective customers looking for the brands and categories of parts you carry.',
    icon: InboxInIcon,
  },
  {
    name: 'Automatic SEO',
    description:
      "Upload unlimited listings to the Gearflow Marketplace to surface your products on Google organically and via Gearflow's free marketing program.",
    icon: SearchIcon,
  },
  {
    name: 'Alt payment methods',
    description:
      'Accept all payment methods from your customers including payment terms that are risk free to you.',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Lowest return rate',
    description:
      'Get the order right the first time with parts requests tied to machine, live messaging, and order reviews and approvals.',
    icon: ReplyIcon,
  },
  {
    name: 'Automated notifications',
    description:
      'Keep your customers notified of the status of every order automatically. No more calls for tracking numbers, order status updates, or availability.',
    icon: DeviceMobileIcon,
  },
]

const DistributorSolutions = () => (
  <Layout>
    {/* Hero section */}
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <img className="h-full w-full object-cover" src={header} alt="" />
            <div className="absolute inset-0 bg-gradient-to-r from-gearflow-dark to-gearflow-light mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">Finally, an online platform built</span>
              <span className="block text-white">to simplify parts ordering</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              Effortlessly open your business up to thousands of potential customers across the
              country that are looking for your help with the parts you sell.
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="flex justify-center">
                <a
                  href="https://landing-page.gearflow.com/request-demo-distributors"
                  className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gearflow bg-white sm:px-8"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
            Unlock parts access
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Connect with customers
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Respond to parts requests seeking your products or upload your listings to the Gearflow
            Marketplace to expand your reach.
          </p>
        </div>
        <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src={screenshot}
            alt=""
          />
        </div>
      </div>
    </div>

    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
          Turnkey platform solution
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Go beyond the transaction
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Give equipment owners everything they need to easily manage their repair and maintenance
          processes from parts request to parts received.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-gearflow-dark to-gearflow-light rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    <div className="pb-16 bg-gradient-to-r from-gearflow-dark to-gearflow-light lg:pb-0 lg:z-10 lg:relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="relative lg:-my-8">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
              <img className="object-cover lg:h-full lg:w-full" src={quote} alt="" />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
            <blockquote>
              <div>
                <svg
                  className="h-12 w-12 text-white opacity-25"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="mt-6 text-2xl font-medium text-white">
                  Heavy equipment buckets change out all of the time; it is not as simple as,
                  &lsquo;what is the make and model&rsquo; &#8212; it&apos;s critical to understand
                  for example the specific dimensions of cutting edges and the spacing of bolt
                  holes, as well as the pocket dimensions of the bucket teeth. Gearflow knows that
                  and can help customers find exactly the right part.
                </p>
              </div>
              <footer className="mt-6">
                <p className="text-base font-medium text-white">Jim Lehman</p>
                <p className="text-base font-medium text-cyan-100">
                  Vice President at Romac Industrial Parts
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <section id="faqs" aria-labelledby="faq-heading" className="bg-white">
      <div className="max-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              <Disclosure as="div" key="How much does it cost to get started?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          How much does it cost to get started?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Joining the Gearflow Supplier Network or responding to parts requests is
                        completely FREE! At this time, we do not charge any fees for your storefront
                        setup, product listings, or parts quotes.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="Is there a limit to how many products I can upload?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Is there a limit to how many products I can upload or parts requests I can
                          respond to?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Nope! You can list as many parts or submit as many quotes as you&#39;d like,
                        free of charge.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="Do I need to have a storefront to join Gearflow?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Do I need to have a storefront to join Gearflow?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        You do not need product listings live on Gearflow in order to become part of
                        the Gearflow Supplier Network (though suppliers with storefronts have
                        greater potential for sales revenue overall!). You can choose to participate
                        by only responding to parts requests (RFQs) from new and current customers
                        who are looking for the parts you sell. We&#39;ll email you parts requests,
                        and you can quote them out with our platform&#39;s easy-to-use tools.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="What are Gearflow’s fees?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          What are Gearflow&#39;s fees?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        We only charge a fee if Gearflow processes the payment for the transaction.
                        If you receive a purchase order from a current customer paying you directly
                        via invoice, there is no fee. For new customer transactions where payment is
                        processed on the Gearflow platform, the fee is 12.65% of the order total.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="How do I get paid?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">How do I get paid?</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        If you participate in Gearflow Payments, you will automatically get paid out
                        directly to your bank account within 1-2 business days, once you&#39;ve
                        marked an order as shipped. Current customer transactions where the payment
                        was not processed on Gearflow will be paid by the customer directly to you
                        via invoice as normal
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure
                as="div"
                key="Does Gearflow offer any APIs into our current ecommerce platform?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Does Gearflow offer any APIs into our current ecommerce platform?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Yes, we do! Please contact us at{' '}
                        <A.T href="mailto:suppliers@gearflow.com">suppliers@gearflow.com</A.T> for
                        more details.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img className="w-full h-full object-cover" src={support} alt="" />
        <div aria-hidden="true" className="absolute inset-0" />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            Unparalleled Support.
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            We&apos;re here to help
          </p>
          <p className="mt-3 text-lg text-gray-300">
            We understand your pains because we have lived them. Our tools are designed to enhance
            the relationships that make this industry go, not replace them.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://landing-page.gearflow.com/request-demo-distributors"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Request a demo
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DistributorSolutions
