import React from 'react'
import clsx from 'clsx'
import { Popover, Transition } from '@headlessui/react'

import useConfig from '@/gf/hooks/useConfig'
import useSession from '../../../hooks/useSession'

import ShopNowNav from './ShopNowNav'
import WhoWeServeNav from './WhoWeServeNav'

const styles = {
  navItem: 'block w-full px-6 py-2 text-left',
}

const Icon = ({ open }) => (
  <svg
    aria-hidden="true"
    className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
    fill="none"
    strokeWidth={2}
    strokeLinecap="round"
  >
    <path
      d="M0 1H14M0 7H14M0 13H14"
      className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
    />
    <path
      d="M2 2L12 12M12 2L2 12"
      className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
    />
  </svg>
)

const Nav = () => {
  const config = useConfig()
  const session = useSession()

  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <Icon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={React.Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 origin-top rounded-2xl bg-white text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 divide-y-1 divide-gray-200"
          >
            <div className="py-4">
              <WhoWeServeNav />
              <ShopNowNav />
              <a href="/cart" className={styles.navItem}>
                View Cart {session && <>({session.cartCount} items)</>}
              </a>
            </div>
            {session && (
              <>
                {session.user?.store && (
                  <div className="py-4">
                    <a href={config.suppliersUrl} className={`${styles.navItem} font-bold`}>
                      Sales Hub
                    </a>
                    <a href={`${config.suppliersUrl}/rfqs`} className={styles.navItem}>
                      Requests
                    </a>
                    <a href={`${config.suppliersUrl}/metrics`} className={styles.navItem}>
                      Reports
                    </a>
                  </div>
                )}
                {session.user ? (
                  <>
                    <div className="py-4">
                      <a href={`${config.buyersUrl}/`} className={`${styles.navItem} font-bold`}>
                        Parts Hub
                      </a>
                      <a href={`${config.buyersUrl}/rfqs`} className={styles.navItem}>
                        Requests
                      </a>
                      <a href={`${config.buyersUrl}/orders`} className={styles.navItem}>
                        Orders
                      </a>
                      <a href={`${config.buyersUrl}/reporting`} className={styles.navItem}>
                        Reports
                      </a>
                    </div>
                    <div className="py-4">
                      <form action="/logout" method="post">
                        <input name="_method" type="hidden" value="delete" />
                        <input name="_csrf_token" type="hidden" value={config.csrfToken} />
                        <button className={styles.navItem} type="submit">
                          Sign Out
                        </button>
                      </form>
                    </div>
                  </>
                ) : (
                  <div className="py-4">
                    <a href="/login" className={styles.navItem}>
                      Sign In
                    </a>
                  </div>
                )}
              </>
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export default Nav
