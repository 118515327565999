import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  title?: string
  headerAction?: JSX.Element | null
  body?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

interface CollapsibleProps extends Props {
  defaultOpen?: boolean
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

const Section: React.FC<Props> = ({ title, headerAction, children, className, body, ...props }) => {
  const header = (title || headerAction) && (
    <div className="flex items-center gap-4 px-6 pt-6 pb-2">
      <div className="flex-grow">{title && <h4 className="text-xl">{title}</h4>}</div>
      <div>{headerAction}</div>
    </div>
  )

  const bodyPaddingTopClassName = title || headerAction ? 'pt-4' : 'pt-6'

  return (
    <section className={`bg-white border rounded-2xl border-gray-200 ${className}`} {...props}>
      {header}
      <div className={`px-6 ${bodyPaddingTopClassName} pb-6`} {...body}>
        {children}
      </div>
    </section>
  )
}

const CollapsibleSection: React.FC<CollapsibleProps> = ({
  title,
  defaultOpen = true,
  children,
  className,
  ...props
}) => (
  <Section className={className} {...props}>
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full flex justify-between items-start text-gray-900">
            <span className="text-xl">{title}</span>
            <span className="h-7 flex items-center">
              <ChevronDownIcon
                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel as="div" className="mt-2 pr-12 flex gap-2">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </Section>
)

export { CollapsibleSection }

export default Section
