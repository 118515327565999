import { cn } from '@/gf/modules/utils'
import { InputHTMLAttributes } from 'react'

const Checkbox = (props: InputHTMLAttributes<HTMLInputElement>) => {
  const className = cn('h-4 w-4 border-gray-300 rounded focus:ring-gearflow', props.className, {
    'text-gearflow': !props.disabled,
    'cursor-pointer ': !props.disabled,
    'cursor-not-allowed text-gray-400': props.disabled,
  })

  return <input {...props} type="checkbox" className={className} />
}

export default Checkbox
