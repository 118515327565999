import { useLocation } from 'react-router-dom'

import Money from '@/gf/modules/Money'
import useToggle from '@/gf/hooks/useToggle'
import useConfig from '@/gf/hooks/useConfig'

import Card from './Card'
import OemBadge from './OemBadge'
import Para from './Para'
import IssueBadge from './IssueBadge'

import { Product } from '../types'

const ProductAddToCart = ({ product }: { product?: Product }) => {
  const config = useConfig()
  const location = useLocation()
  const [backorderedModalIsOpen, backorderedModalToggler] = useToggle(false)

  return (
    <Card>
      {product && (
        <>
          <div className="flex gap-4">
            <h1 className="text-xl">{product.name}</h1>
            {product.isOem && (
              <div>
                <OemBadge />
              </div>
            )}
          </div>

          <div className="grid gap-2">
            <div>
              {product.availability === 'backordered' && <IssueBadge>Backordered</IssueBadge>}
              {product.availability === 'out_of_stock' && <IssueBadge>Out of Stock</IssueBadge>}
            </div>

            {product.mpn && (
              <h2 className="text-sm">
                <span className="text-gray-500">Part Number:</span> {product.mpn}
              </h2>
            )}

            {product.altMpn && (
              <h5 className="text-sm break-words">
                <span className="text-gray-500">Alternative Part Number(s):</span> {product.altMpn}
              </h5>
            )}

            <Para dangerouslySetInnerHTML={{ __html: product.shortDescription }} />
          </div>

          <div className="text-2xl my-6 font-bold">{Money.format(product.salePrice)}</div>

          <form action="/cart" method="post">
            <input name="_csrf_token" type="hidden" value={config.csrfToken} />
            <input id="product" name="product" type="hidden" value={product.id} />
            <input id="redirect_to" name="redirect_to" type="hidden" value={location.pathname} />

            {backorderedModalIsOpen && (
              <div className="grid gap-2">
                <Para>
                  This product is backordered.
                  {product.leadTime && <> There is an estimated lead time of {product.leadTime}.</>}
                </Para>
                <Para>Are you sure you want to add it to your cart?</Para>
                <div className="flex mt-3">
                  <button
                    type="button"
                    onClick={(_e) => backorderedModalToggler.off()}
                    className="grow py-3 border border-transparent leading-6 rounded-md text-white bg-gray-400 hover:bg-orange-700 focus:outline-none focus:ring transition duration-150 ease-in-out mr-2"
                  >
                    No
                  </button>{' '}
                  <button
                    type="submit"
                    className="grow py-3 border border-transparent leading-6 rounded-md text-white bg-gearflow hover:bg-orange-700 focus:outline-none focus:ring transition duration-150 ease-in-out"
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}

            <section className={`flex gap-4 ${backorderedModalIsOpen && 'hidden'}`}>
              {product.availability === 'out_of_stock' ? (
                <button
                  type="button"
                  className="grow px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gray-400 cursor-not-allowed"
                  disabled
                >
                  Out of Stock
                </button>
              ) : (
                <>
                  <select className="border sort rounded-md" id="quantity" name="quantity">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <button
                    onClick={
                      product.availability === 'backordered'
                        ? (_e) => backorderedModalToggler.on()
                        : undefined
                    }
                    type={product.availability === 'backordered' ? 'button' : 'submit'}
                    className="grow px-5 py-3 border border-transparent leading-6 rounded-md text-white bg-gearflow hover:bg-orange-700 focus:outline-none focus:ring transition duration-150 ease-in-out"
                  >
                    Add to cart
                  </button>
                </>
              )}
            </section>
          </form>
        </>
      )}
    </Card>
  )
}

export default ProductAddToCart
