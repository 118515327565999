import useSearchProducts from '../hooks/useSearchProducts'

import Section from './Section'
import ProductCard from './ProductCard'

import { Product } from '../types'

const MoreSupplierProducts = ({ product, count = 7 }: { product?: Product; count?: number }) => {
  const { data } = useSearchProducts({
    variables: { storeId: product?.store.id, limit: count + 1 },
    skip: !product,
  })

  if (product === undefined || data === undefined) return null

  const products = data.productsSearch.products.filter((p) => p.id !== product.id).slice(0, count)

  return (
    <Section
      title="More from this Supplier"
      headerAction={<a href={`/store/${product.store.slug}`}>View All</a>}
      body={{ className: 'flex overflow-x-auto' }}
    >
      <div className="flex flex-nowrap pl-4 pr-3 pt-2 pb-4 gap-4">
        {products.map((p) => (
          <div key={p.id} className="w-40 md:w-48">
            <ProductCard product={p} key={p.id} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default MoreSupplierProducts
