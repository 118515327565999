import FoundersPhoto from './FoundersPhoto.png'

export default function AboutBottom() {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-scale-down lg:absolute lg:h-full"
            src={FoundersPhoto}
            alt=""
          />
        </div>
      </div>
      <div className="relative px-6 pt-12 pb-16 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="mx-auto max-w-prose text-base lg:ml-auto lg:mr-0 lg:max-w-lg">
            <h2 className="font-semibold leading-6 text-orange-600">Our story</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Why the parts process matters to us
            </h3>
            <p className="mt-8 text-lg text-gray-500">
              Gearflow Founder & CEO Luke Powers spent most of his career working for his
              family&#39;s construction equipment rental business in Chicago.
            </p>
            <div className="prose prose-indigo mt-5 text-gray-500">
              <p>
                It struck him that although the largest cost for contractors is equipment downtime,
                the process to get equipment back up and running is painful for everyone, something
                he repeatedly witnessed firsthand. It is marked by complicated access to parts
                suppliers, chaotic communication within fleet teams and to their vendors, and a lack
                of visibility into parts ordering activity.
              </p>
              <p>
                Meanwhile, there are thousands of parts suppliers who want to better serve their
                customers … but lack the digital platform to do so.
              </p>
              <p>
                So in 2018, Luke set out with Ben Preston, cofounder & COO, to build a better way.
              </p>
              <p>
                At Gearflow, we are here to help you manage your entire parts ordering process with
                an all-in-one platform, giving you instant supplier access, centralized
                communication, and real-time reporting.
              </p>
              <p>We would love to hear from you on how we can best support you and your team.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
