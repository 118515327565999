import FacebookIcon from '../svgs/FacebookIcon'
import InstagramIcon from '../svgs/InstagramIcon'
import TwitterIcon from '../svgs/TwitterIcon'
import LinkedInIcon from '../svgs/LinkedInIcon'
import YouTubeIcon from '../svgs/YouTubeIcon'
import LayoutContainer from './LayoutContainer'
import SubscribeToNewsletter from './SubscribeToNewsletter'
import logoGear from './Footer/logo_gear.svg'

const footerNavigation = {
  company: [
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact-us' },
    {
      name: 'Jobs',
      href: 'https://www.notion.so/Gearflow-is-hiring-a881f7b39ea54d76a1f07c2db41fa48b',
    },
  ],
  resources: [
    { name: 'Press', href: '/press' },
    { name: 'Blog', href: 'https://blog.gearflow.com' },
  ],
  marketplace: [
    { name: 'Accessories', href: '/c/accessories' },
    { name: 'Attachments', href: '/c/attachments' },
    { name: 'Equipment', href: '/c/equipment' },
    { name: 'Parts', href: '/c/parts' },
    { name: 'Tools', href: '/c/tools' },
  ],
  howItWorks: [{ name: 'For Fleets', href: '/how-it-works' }],
  legal: [
    { name: 'Privacy', href: '/privacy-policy' },
    { name: 'Terms & Conditions', href: '/terms-and-conditions' },
    // { name: 'Supplier Terms & Conditions', href: '/supplier-terms-and-conditions' },
  ],
  social: [
    {
      name: 'Gearflow on Facebook',
      href: 'https://www.facebook.com/Gearflow/',
      icon: FacebookIcon,
    },
    {
      name: 'Gearflow on Instagram',
      href: 'https://www.instagram.com/gearflow_inc/',
      icon: InstagramIcon,
    },
    {
      name: 'Gearflow on Twitter',
      href: 'https://twitter.com/gearflow1',
      icon: TwitterIcon,
    },
    {
      name: 'Gearflow on LinkedIn',
      href: 'https://www.linkedin.com/company/gearflow-com',
      icon: LinkedInIcon,
    },
    {
      name: 'Gearflow on YouTube',
      href: 'https://www.youtube.com/channel/UCQdlalqOpENSJTd5w7fDeHA',
      icon: YouTubeIcon,
    },
  ],
}

const Section = ({ title, links }: { title: string; links: { name: string; href: string }[] }) => (
  <li className="space-y-4">
    <h6 className="uppercase text-base leading-6 font-medium">{title}</h6>
    <ul className="space-y-4">
      {links.map((link) => (
        <li key={link.name}>
          <a href={link.href} className="text-sm leading-5">
            {link.name}
          </a>
        </li>
      ))}
    </ul>
  </li>
)

const Footer = () => (
  <LayoutContainer className="bg-orange-500 text-white">
    <footer aria-labelledby="footer-heading">
      <div id="footer-heading" className="sr-only">
        Footer
      </div>

      <div className="py-16 flex flex-col sm:flex-row gap-x-8 gap-y-16">
        <div className="w-full sm:w-96 space-y-4">
          <div className="pb-4">
            <img src={logoGear} alt="Gearflow logo gear" className="w-16 h-16" />
          </div>
          <h5 className="font-medium text-base leading-6">Subscribe to our updates</h5>
          <p className="text-sm leading-5">
            By signing up, you authorize Gearflow to send communications (including text messages if
            applicable) with offers & other information, possibly using automated technology.
            Consent is not a condition of purchase. Message/data rates apply.
          </p>

          <SubscribeToNewsletter />
        </div>

        <ul className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-8">
          <Section title="Company" links={footerNavigation.company} />
          <Section title="Resources" links={footerNavigation.resources} />
          <Section title="Marketplace" links={footerNavigation.marketplace} />
          <Section title="How it Works" links={footerNavigation.howItWorks} />
          <Section title="Legal" links={footerNavigation.legal} />
        </ul>
      </div>

      <hr className="border-white" />

      <div className="flex flex-col sm:flex-row gap-4 items-center pt-8 pb-12">
        <p className="text-xs md:text-sm flex-grow">
          &copy; 2018 - {new Date().getFullYear()} Gearflow. All rights reserved.
        </p>
        <ul className="flex gap-x-8">
          {footerNavigation.social.map((item) => (
            <li key={item.name}>
              <a href={item.href} className="">
                <span className="sr-only">{item.name}</span>
                <item.icon className="" aria-hidden="true" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  </LayoutContainer>
)

export default Footer
