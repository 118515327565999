import { Link } from 'react-router-dom'

import useSearchProducts from '../hooks/useSearchProducts'
import Section from './Section'

import ProductCard from './ProductCard'

import { Product, SourceProduct, Category } from '../types'

const RelatedProducts = ({
  product,
  category,
  count = 7,
}: {
  product?: Product | SourceProduct
  category?: Category | null
  count?: number
}) => {
  const { data } = useSearchProducts({
    variables: { inSubtreeOfCategory: category?.id, limit: count + 1 },
    skip: !category,
  })

  if (data === undefined) return null

  const products = data.productsSearch.products.filter((p) => p.id !== product?.id).slice(0, count)

  const headerAction = category && <Link to={`/c/${category.slugPath}`}>View All</Link>

  return (
    <Section
      title="Related Products"
      headerAction={headerAction}
      body={{ className: 'flex overflow-x-auto' }}
    >
      <div className="flex flex-nowrap pl-4 pr-3 pt-2 pb-4 gap-4">
        {products.map((p) => (
          <div key={p.id} className="w-40 md:w-48">
            <ProductCard product={p} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default RelatedProducts
