import { Category } from '../types'

// private
const findBySlugs = (categories: Category[], slugs: string[]): Category | null => {
  const [slug, ...restSlugs] = slugs

  const category = categories.find((c) => c.slug === slug)

  if (!category) return null

  if (restSlugs.length === 0) return category

  return findBySlugs(category.children, restSlugs)
}

const findById = (categories: Category[], id: string): Category | null => {
  for (let i = 0; i < categories.length; i += 1) {
    if (categories[i].id === id) return categories[i]

    const category = findById(categories[i].children, id)

    if (category) return category
  }

  return null
}

const findBySlugPath = (categories: Category[], slugPath: string) => {
  const slugs = slugPath.split('/')
  return findBySlugs(categories, slugs)
}

export default { findBySlugPath, findById }
