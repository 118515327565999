import useConfig from '@/gf/hooks/useConfig'
import { microsoftAuthUrl } from '@/gf/modules/Microsoft'
import { SsoLinkType, useMicrosoftLinkLazyQuery, useSsoSignInMutation } from '@/retail/_gen/gql'
import useGqlClient from '@/retail/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import useSignUp from '../SignUp/useSignUp'

const linkType = SsoLinkType.Microsoft

const useContinueWithMicrosoft = ({
  onSignedIn,
  onNoMatch,
}: {
  onSignedIn: (returnPath: string) => void
  onNoMatch: () => void
}) => {
  const config = useConfig()
  const client = useGqlClient()
  const location = useLocation()
  const [query, setQuery] = useQueryParams({ 'link-type': StringParam, 'link-id': StringParam })
  const [linkId, setLinkId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [signIn] = useSsoSignInMutation({ client })
  const queryLink = useMicrosoftLinkLazyQuery({ client })[0]
  const { updateFields, reset } = useSignUp()

  const authUrl = microsoftAuthUrl({
    redirectUri: `${config.gfBaseUrl}/oauth/microsoft`,
    state: location.pathname,
  })

  const maybeSignIn = async () => {
    if (!linkId) return
    const ssoSignIn = (await signIn({ variables: { linkId, linkType } })).data?.ssoSignIn

    if (ssoSignIn) {
      onSignedIn(ssoSignIn)
      return
    }

    const link = (await queryLink({ variables: { linkId } })).data?.microsoftLink

    // TODO: this should not happen
    if (!link) {
      setLoading(false)
      return
    }

    reset()

    updateFields({
      sso: { linkId, linkType, email: link.me.mail },
      name: `${link.me.givenName} ${link.me.surname}`,
      phoneNumber: link.me.mobilePhone || '',
      code: `${parseInt('0xFFC1074', 16)}`,
    })

    onNoMatch()
  }

  useEffect(() => {
    if (query['link-type'] === linkType && query['link-id']) {
      setLoading(true)
      setLinkId(query['link-id'])
      setQuery({ 'link-type': undefined, 'link-id': undefined })
    }
  }, [])

  // A second useEffect is needed to avoid signIn running twice in React strict mode
  useEffect(() => {
    maybeSignIn()
  }, [linkId])

  return { authUrl, loading }
}

export default useContinueWithMicrosoft
