import useConfig from '@/gf/hooks/useConfig'
import React from 'react'
import { useLocation } from 'react-router-dom'

import FacebookIcon from '../svgs/FacebookIcon'
import InstagramIcon from '../svgs/InstagramIcon'
import LinkedInIcon from '../svgs/LinkedInIcon'
import TwitterIcon from '../svgs/TwitterIcon'
import YouTubeIcon from '../svgs/YouTubeIcon'

const footerNavigation = {
  company: [
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact-us' },
    {
      name: 'Jobs',
      href: 'https://www.notion.so/Gearflow-is-hiring-a881f7b39ea54d76a1f07c2db41fa48b',
    },
    { name: 'Press', href: '/press' },
    { name: 'Blog', href: 'https://blog.gearflow.com' },
  ],
  solutions: [
    { name: 'Equipment Owners', href: '/solutions/buyer' },
    { name: 'Dealers', href: '/solutions/dealer' },
    { name: 'Manufacturers', href: '/solutions/manufacturer' },
    { name: 'Distributors', href: '/solutions/distributor' },
    { name: 'Pricing', href: '/pricing/parts-hub' },
  ],
  support: [
    { name: 'Equipment Owners FAQs', href: '/solutions/buyer#faqs' },
    { name: 'Dealers FAQs', href: '/solutions/dealer#faqs' },
    { name: 'Manufacturers FAQs', href: '/solutions/manufacturer#faqs' },
    { name: 'Distributors FAQs', href: '/solutions/distributor#faqs' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy-policy' },
    { name: 'Terms & Conditions', href: '/terms-and-conditions' },
    { name: 'Supplier Terms & Conditions', href: '/supplier-terms-and-conditions' },
  ],
  social: [
    {
      name: 'Gearflow on Facebook',
      href: 'https://www.facebook.com/Gearflow/',
      icon: FacebookIcon,
    },
    {
      name: 'Gearflow on Instagram',
      href: 'https://www.instagram.com/gearflow_inc/',
      icon: InstagramIcon,
    },
    {
      name: 'Gearflow on Twitter',
      href: 'https://twitter.com/gearflow1',
      icon: TwitterIcon,
    },
    {
      name: 'Gearflow on LinkedIn',
      href: 'https://www.linkedin.com/company/gearflow-com',
      icon: LinkedInIcon,
    },
    {
      name: 'Gearflow on YouTube',
      href: 'https://www.youtube.com/channel/UCQdlalqOpENSJTd5w7fDeHA',
      icon: YouTubeIcon,
    },
  ],
}

const SubscribeForm = () => {
  const config = useConfig()
  const location = useLocation()
  const [email, setEmail] = React.useState('')
  const [msg, setMsg] = React.useState<string>()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMsg('')

    const data = {
      _csrf_token: config.csrfToken,
      email,
      'g-recaptcha-response': event.target['g-recaptcha-response'].value,
      query_params: location.search.replace('?', ''),
      redirect_to: location.pathname,
    }

    fetch('/email/subscribe', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((resp) => {
      if (resp.status === 200) {
        setMsg('Thanks for subscribing!')
        setEmail('')
      } else {
        resp
          .json()
          .then((respData: { error?: string }) =>
            setMsg(respData.error || "We're sorry, something went wrong. Please try again.")
          )
      }
    })
  }

  return (
    <form onSubmit={onSubmit} className="mt-4">
      <div id="recaptcha" />
      <div className="sm:flex sm:max-w-md">
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="email-address"
          id="email-address"
          autoComplete="email"
          required
          className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-nonefocus:placeholder-gray-400"
          placeholder="Enter your email"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            className="w-full flex items-center justify-center bg-gradient-to-r bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gearflow"
          >
            Subscribe
          </button>
        </div>
      </div>

      {msg && (
        <p className="mt-4 text-gray-600 text-base leading-6" role="alert">
          {msg}
        </p>
      )}
    </form>
  )
}

const Footer = () => (
  <footer className="bg-gray-50" aria-labelledby="footer-heading">
    <div id="footer-heading" className="sr-only">
      Footer
    </div>
    <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="grid grid-cols-4 gap-8 xl:col-span-2">
          <div className="md:grid md:grid-cols-3 md:gap-8">
            <div className="mt-12 md:mt-0 md:col-span-3">
              <div className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </div>
              <ul className="mt-4 space-y-4">
                {footerNavigation.company.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-8">
            <div className="mt-12 md:mt-0 md:col-span-3">
              <div className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Solutions
              </div>
              <ul className="mt-4 space-y-4">
                {footerNavigation.solutions.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-8">
            <div className="mt-12 md:mt-0 md:col-span-3">
              <div className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </div>
              <ul className="mt-4 space-y-4">
                {footerNavigation.support.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-3 md:gap-8">
            <div className="mt-12 md:mt-0 md:col-span-3">
              <div className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </div>
              <ul className="mt-4 space-y-4">
                {footerNavigation.legal.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-12 xl:mt-0">
          <div className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
            Subscribe to our newsletter
          </div>
          <p className="mt-4 text-base text-gray-500">
            By signing up, you authorize Gearflow to send communications (including text messages if
            applicable) with offers & other information, possibly using automated technology.
            Consent is not a condition of purchase. Message/data rates apply.
          </p>
          <SubscribeForm />
        </div>
      </div>
      <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
        <div className="flex space-x-6 md:order-2">
          {footerNavigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          &copy; 2018 - {new Date().getFullYear()} Gearflow. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
