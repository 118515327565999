import { gql, useMutation } from '@apollo/client'

import { AcceptDealerInviteForm } from '../types'

interface Data {
  acceptDealerInvite: boolean
}

type Variables = AcceptDealerInviteForm

const mutation = gql`
  mutation AcceptDealerInvite(
    $dealerInviteId: ID!
    $companyName: String!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    acceptDealerInvite(
      dealerInviteId: $dealerInviteId
      companyName: $companyName
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      passwordConfirmation: $passwordConfirmation
    )
  }
`

const useAcceptDealerInvite = () => {
  const [mutate] = useMutation<Data, Variables>(mutation)

  return (variables: Variables) => mutate({ variables })
}

export default useAcceptDealerInvite
