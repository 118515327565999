import gearBg2 from '@/retail/components/svgs/gear_bg_2.svg'
import SiteUrls from '@/retail/modules/SiteUrls'
import { motion } from 'framer-motion'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import WistiaVideo from '@/gf/components/Video/Wistia'

const IntroHero = () => (
  <LayoutContainer
    className="how-it-works-top-bg"
    style={{
      backgroundImage: `url(${gearBg2})`,
    }}
    childrenClassName="pt-16 pb-40 sm:pb-[13rem]"
  >
    <section className="flex flex-col md:flex-row gap-8 sm:gap-12 xl:gap-16 sm:items-center">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, delayChildren: 0.2, staggerChildren: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="space-y-8 md:order-2"
      >
        <hgroup className="space-y-2">
          <motion.h2 className="text-xl font-medium block">Why Parts Hub Pro?</motion.h2>
          <motion.p
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { type: 'spring', stiffness: 300, damping: 24 },
              },
              hidden: { opacity: 0, y: -20, transition: { duration: 0.5 } },
            }}
            className="block text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem] font-medium"
          >
            Your competitive edge starts with your parts
          </motion.p>
        </hgroup>
        <motion.p
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: { opacity: 0, y: -20, transition: { duration: 0.7 } },
          }}
          className="text-base xl:text-xl"
        >
          Reduce your costs, spend less time waiting on parts, and get the insights you need to fuel
          equipment uptime, so your org can make more money and win more jobs.
        </motion.p>
        <div className="gap-x-4 gap-y-2 flex flex-col sm:flex-row items-row sm:items-center justify-start">
          <LinkButton href="/signup">Try It Now!</LinkButton>
          <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL} target="_blank" variation="secondary">
            Book a Discovery Call
          </LinkButton>
        </div>
      </motion.div>

      <div className="relative w-full md:w-1/2 md:order-1 flex-shrink-0 rounded-md overflow-hidden shadow-md">
        <WistiaVideo videoId="wwoqgta0lt" title="How Parts Hub Pro Works Video" />
      </div>
    </section>
  </LayoutContainer>
)

export default IntroHero
