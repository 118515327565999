import qs from 'query-string'
import ReactPagination from 'react-paginate'
import { useLocation } from 'react-router-dom'

import { type Pagination } from '../../types'

import useWindowWidth from '@/gf/hooks/useWindowWidth'

const Pagination = ({
  pagination,
  page,
  updatePage,
}: {
  pagination?: Pagination
  page: number
  updatePage: (newPage: number) => void
}) => {
  const location = useLocation()
  const params = qs.parse(location.search)

  const windowWidth = useWindowWidth()
  const xsm = windowWidth < 410
  const sm = windowWidth < 640
  const md = windowWidth < 768

  const onPageChange = ({ selected }: { selected: number }) => {
    updatePage(selected + 1)
  }

  if (!pagination) return null

  return (
    <div className="flex justify-end">
      {pagination.totalResults > 0 && (
        <ReactPagination
          forcePage={page - 1}
          pageCount={pagination.totalPages}
          pageRangeDisplayed={sm ? 0 : md ? 3 : 5}
          marginPagesDisplayed={xsm ? 0 : 1}
          onPageChange={onPageChange}
          hrefBuilder={(pageIndex, _pageCount, _selectedPage) =>
            `${location.pathname}?${qs.stringify({ ...params, page: pageIndex })}`
          }
          containerClassName="flex"
          pageLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          breakLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          previousLinkClassName="rounded-l-md bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          nextLinkClassName="rounded-r-md bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          activeLinkClassName="z-10 bg-gray-200 text-gray-500"
        />
      )}
    </div>
  )
}

export default Pagination
