import gearBg from '@/retail/components/svgs/gear_bg.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import DesktopImage from './BookDemoHero/DesktopImage'
import showcasePicMobile from './BookDemoHero/showcase-pic-mobile.png'

const words = [
  ['chaotic', 'controlled'],
  ['reactive', 'proactive'],
  ['a cost center', 'a margin maker'],
]

const AnimateWord = ({ className, children, isVisible }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.span
        className={className}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.span>
    )}
  </AnimatePresence>
)

const BookDemoHero = () => {
  const [index, setIndex] = useState(0)
  const timerRef = useRef<ReturnType<typeof setInterval>>()
  // const imageRef = useRef<HTMLDivElement>(null)
  // const { scrollYProgress } = useScroll({
  //   target: imageRef,
  //   offset: ['end end', 'end start'],
  // })

  // const opacity = useTransform(scrollYProgress, [0.2, 1], [1, 0.5])
  // const scale = useTransform(scrollYProgress, [0.2, 1], [1, 0.7])

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setIndex((prev) => {
        const nextIndex = prev + 1
        return nextIndex < words.length ? nextIndex : 0
      })
    }, 4000)

    return () => clearInterval(timerRef.current)
  }, [index])

  return (
    <LayoutContainer
      className="home-gear-bg"
      style={{
        backgroundImage: `url(${gearBg})`,
      }}
    >
      <section className="flex flex-col sm:flex-row sm:gap-x-3 gap-y-16 sm:gap-y-0 items-baseline sm:items-center pt-8 sm:pt-16 pb-8 sm:pb-20 xl:pb-32">
        <div className="flex-grow space-y-8">
          <h2 className="font-medium text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem]">
            <span className="block">Take your parts process</span>
            <span className="block">
              from{' '}
              <AnimateWord className="inline-block" key={words[index][0]} isVisible>
                <span>
                  <strong className="font-bold">{words[index][0]}</strong> to
                </span>
              </AnimateWord>{' '}
            </span>

            <AnimateWord className="block font-bold" key={words[index][1]} isVisible>
              <strong>{words[index][1]}</strong>
            </AnimateWord>
          </h2>
          <p className="text-base sm:text-xl leading-6 sm:leading-7">
            Get the competitive advantage your heavy equipment <br className="hidden xl:block" />{' '}
            fleet needs with the software parts teams love.
          </p>
          <div className="flex gap-x-4">
            <LinkButton href="/signup">Try It Now!</LinkButton>
            <LinkButton href="/how-it-works" variation="secondary">
              Learn More
            </LinkButton>
          </div>
        </div>
        <figure className="w-full sm:w-[28rem] xl:w-[39rem] relative flex-shrink-0">
          <div className="sm:hidden relative w-[145vw] -left-[52vw]">
            <img
              src={showcasePicMobile}
              alt="Gearflow makes process better"
              className="w-full h-full object-cover"
            />
          </div>

          <DesktopImage />
        </figure>
      </section>
    </LayoutContainer>
  )
}

export default BookDemoHero
