import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const CanonicalRedirect = ({ path }) => {
  const location = useLocation()

  if (location.pathname === path) return null

  return (
    <Helmet>
      <meta httpEquiv="refresh" content={`0; url=${path}`} />
    </Helmet>
  )
}

export default CanonicalRedirect
